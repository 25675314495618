import React, { useState, useEffect } from "react";
import { Table, Spin, Tag } from "antd";
import numberFormat from "../../../Function/NumberFormat2float";
import OpenNotification from "../../../Toast/OpenNotification";

import Adduser from "./Adduser";
import API_URL from "../../.././config/api";

import { Link } from "react-router-dom";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { IconButton, TextField } from "@mui/material";
import Swal from "sweetalert2";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";

import { useWebSocket } from "../../back/WebSocketContext";
import { getCurrentUser } from "../../../services/auth.service";
import { useTranslation } from "react-i18next";

function User_all() {
  const { sendMessage, websocket } = useWebSocket();
  const {t} = useTranslation();
  const token = getCurrentUser();
  const [allUser, setAllUser] = useState([]);
  const [allUserBackup, setAllUserBackup] = useState([]);
  const [loading, setLoading] = useState(true);
  const [getId, setGetId] = useState(null);

  const [isModalUser, setIsModalUser] = useState(false);

  const [inputSearch, setInputSearch] = useState("");

  useEffect(() => {
    get_Alluser();
  }, []);

  useEffect(() => {
    if (websocket) {
      websocket.onmessage = (message) => {
        const data = JSON.parse(message.data);

        if (data.type === "refreshUsers") {
          get_Alluser();
        }
      };
    }

    return () => {
      if (websocket) {
        websocket.onmessage = null;
      }
    };
  }, [websocket]);

  const handleUpdateCredit = async (amount,phone) => {
    await API_URL.post(`api/creditadmin/addCreditadmin`, {
      credittype: 1,
      amount: amount,
      note: `Click + ${amount}`,
      phone: phone,
      userId: token.id,
    })
      .then((res) => {
        OpenNotification({ message: `${t("finish")}`, type: 1 });
        try {
          sendMessage({ type: "refreshCredits" });
          sendMessage({ type: "refreshUsers" });
        } catch (error) {}
    
        return res.data;
      })
      .catch((err) => {
        err.response.data.status === 400
          ? OpenNotification({ message: `${t("this_username_does_not_exist")}`, type: 3 })
          : OpenNotification({ message: `${t("an_error_occurred")}`, type: 4 });
      });
  };

  const showModalUser = () => {
    setIsModalUser(!isModalUser);
    if (isModalUser === true) {
      setGetId(null);
    }
  };

  const get_Alluser = async () => {
    setAllUser([]);
    setAllUserBackup([]);
    setLoading(true);
    await API_URL.get(`api/people/allPeople`)
      .then((res) => {
        const getalluser = res.data;
        if (getalluser.length !== 0) {
          getalluser.map((e) => {
            e.credit = e.credit.toString();
          });
          getalluser.sort((a, b) => (b.createdAt > a.createdAt ? 1 : -1));
          setAllUser(getalluser);
          setAllUserBackup(getalluser);
        }
      })
      .catch((err) => {
        OpenNotification({ message: `${t("an_error_occurred")}`, type: 4 });
      });
    setLoading(false);
  };

  const showDeleteConfirm = (id, user) => {
    Swal.fire({
      title: `${t("confirm_delete_this_user")}`,
      text: user.firstname + "\t\t\t" + user.lastname,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `${t("confirm_deletion")}`,
      cancelButtonText: `${t("cancel")}`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await API_URL.delete(`api/people/deletePeople/${id}`)
          .then(() => {
            try {
              sendMessage({ type: "refreshUsers" });
            } catch (error) {
              
            }
            OpenNotification({ message: `${t("deleted_successfully")}`, type: 1 });
          })
          .catch((err) => {
    
            OpenNotification({ message: `${t("an_error_occurred")}`, type: 4 });
          });
      }
    });
  };

  const columns_alluser = [
    {
      title: "Username",
      dataIndex: "username",
      render: (username) => (
        <p className="text-xs  my-auto truncate"> {username}</p>
      ),
    },
    {
      title: `${t("phone")}`,
      dataIndex: "phone",
      align: "center",

      render: (phone) => (
        <p className="text-xs text-black my-auto px-2">{phone}</p>
      ),
    },
    {
      title: `${t("password")}`,
      dataIndex: "password",
      align: "center",

      render: (password) => (
        <p className="text-xs text-black my-auto px-2">{password}</p>
      ),
    },

    // {
    //   title: `${t("credit")}`,
    //   dataIndex: "credit",
    //   align: "center",
    //   width: "20%",
    //   render: (credit,allUser) => (
    //     <div className="flex align-middle gap-1">
    //       <Tag color="green" className="flex align-middle">
    //         <p className="text-xs text-black my-auto px-2 font-semibold">
    //           {numberFormat(credit)}
    //         </p>
    //       </Tag>
    //       <button onClick={()=>handleUpdateCredit(2,allUser.phone)} className="py-1 px-5 hover:text-green-900 bg-green-500 border-green-500 shadow-sm text-white hover:bg-white border-2 rounded-md my-auto flex align-middle text-shadow-1">
    //         <AddCircleIcon className="scale-75 mr-1 text-shadow-1" />2
    //       </button>
    //       <button onClick={()=>handleUpdateCredit(4,allUser.phone)} className="py-1 px-5 hover:text-green-900 bg-green-500 border-green-500 shadow-sm text-white hover:bg-white border-2 rounded-md my-auto flex align-middle text-shadow-1">
    //         <AddCircleIcon className="scale-75 mr-1 text-shadow-1" />
    //         4
    //       </button>
    //       <button onClick={()=>handleUpdateCredit(10,allUser.phone)} className="py-1 px-5 hover:text-green-900 bg-green-500 border-green-500 shadow-sm text-white hover:bg-white border-2 rounded-md my-auto flex align-middle text-shadow-1">
    //         <AddCircleIcon className="scale-75 mr-1 text-shadow-1" />
    //         10
    //       </button>
   
    //     </div>
    //   ),
    // },
    {
      title: `${t("management")}`,
      align: "center",
      dataIndex: "id",
      key: "id",
      render: (id, allUser) => (
        <div className="flex justify-center">
          <Link className="my-auto" to={`/admin/user/${id}`}>
            <SearchIcon fontSize="medium" color="warning" />
          </Link>
          <IconButton onClick={() => showDeleteConfirm(id, allUser)}>
            <DeleteForeverIcon fontSize="small" color="error" />
          </IconButton>
        </div>
      ),
    },
  ];
  return (
    <div>
      {isModalUser ? (
        <Adduser
          isModalUser={isModalUser}
          showModalUser={showModalUser}
          get_Alluser={get_Alluser}
          allUser={allUser}
          getId={getId}
        />
      ) : (
        <></>
      )}
      <div className="my-2 md:flex items-end">
        <div className="w-full md:w-2/3 hidden md:flex items-center justify-center mb-3 md:mb-0">
          <div className=" w-1/2 flex my-auto">
            <div className="w-5/6 flex bg-sky-600 rounded-md  px-2 py-1 shadow-lg">
              <div className="py-3">
                <PermContactCalendarIcon
                  fontSize="small"
                  className="text-white mx-5 my-auto"
                />
              </div>
              <div className="ml-7 my-auto py-1 h-auto">
                <p className="text-left text-sm font-semibold text-white drop-shadow-2xl shadow-black my-auto mr-4">
                {t("total_number_user")}
                </p>
                <p className="text-left truncate text-md my-auto md:text-sm font-semibold text-white drop-shadow-2xl shadow-black">
                  {allUser.length}
                </p>
              </div>
            </div>
          </div>
          <div className="my-auto w-1/2  mx-auto md:ml-10 text-center">
            <button
              onClick={showModalUser}
              className=" justify-center text-center py-3 md:py-2 px-10 rounded-md shadow-lg bg-red-500 hover:bg-red-700 text-white text-sm md:text-md"
            >
              <AddCircleIcon className="my-auto mr-1 md:mr-5" />
              {t("add_user")}
            </button>
          </div>
        </div>

        <div className="w-full  flex md:hidden items-center justify-center mb-3">
          <div className=" w-1/2 flex bg-sky-600 rounded-md  px-2 shadow-lg my-auto mr-1">
            <div className="py-3 w-1/6">
              <PermContactCalendarIcon
                fontSize="small"
                className="text-white mx-5 my-auto"
              />
            </div>
            <div className="ml-7 my-auto py-1 h-auto w-5/6">
              <p className="text-left text-xs font-semibold text-white drop-shadow-2xl shadow-black my-auto mr-4 txtinfo">
              {t("total_number_user")}
              </p>
              <p className="text-left truncate text-md my-auto md:text-sm font-semibold text-white drop-shadow-2xl shadow-black txtinfo">
                {allUser.length}
              </p>
            </div>
          </div>
          <div className="my-auto w-1/2  mx-auto md:ml-10 text-center">
            <button
              onClick={showModalUser}
              className=" w-full justify-center text-center py-3 md:py-2 px-10 rounded-md shadow-lg bg-red-500 hover:bg-red-700 text-white text-xs txtinfo"
            >
              <div className="flex justify-center">
                <AddCircleIcon
                  fontSize="small"
                  className="my-auto mr-1 md:mr-5"
                />
                <p className="txtinfo my-auto">{t("add_user")}</p>
              </div>
            </button>
          </div>
        </div>

        <div className="w-full md:w-1/3 mx-auto  flex">
          <TextField
            className="bg-white rounded-md"
            name="password"
            size="small"
            color="secondary"
            id="outlined-textarea"
            autoComplete="off"
            label={t("search_credit_phone_number")}
            // value={newpass}
            // onChange={(e) => setNewpass(e.target.value)}
            InputProps={{
              endAdornment: <SearchIcon position="end"></SearchIcon>,
            }}
            fullWidth
            value={inputSearch}
            onChange={(e) => {
              const currValue = e.target.value;
              setInputSearch(currValue);
              const filteredData = allUserBackup.filter(
                (entry) =>

                  entry.username.toLowerCase().includes(currValue.toLowerCase()) ||
                  entry.phone.toLowerCase().includes(currValue.toLowerCase()) ||
                  entry.password
                    .toLowerCase()
                    .includes(currValue.toLowerCase()) ||
                  entry.credit.includes(currValue)
              );
              setAllUser(filteredData);
            }}
          />
        </div>
      </div>
      <Table
        size="middle"
        scroll={{
          x: 800,
        }}
        className="table-striped-rows"
        pagination={{ pageSize: 15 }}
        rowKey="id"
        columns={columns_alluser}
        dataSource={allUser}
        loading={{
          indicator: (
            <div>
              <Spin size="large" />
            </div>
          ),
          spinning: loading,
        }}
      />
    </div>
  );
}
export default User_all;
