// const numberFormat = (number) => {
//   // Convert the number to a string
//   let formattedNumber = number.toString();

//   // Remove unnecessary trailing zeros but keep significant digits
//   if (formattedNumber.includes('.')) {
//     formattedNumber = formattedNumber.replace(/(\.\d+?[1-9])0+$/, '$1');
//   }

//   // Format the number with commas
//   return new Intl.NumberFormat().format(formattedNumber);
// };

const numberFormat = (number) => {
  // Convert the input to a number
  let number1 = parseFloat(number);

  // Check if the number is greater than 1,000
  if (number1 >= 1000) {
    return number1.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 8,
    });
  } else {
    // For numbers less than 1000, remove trailing zeros after decimal
    let formatted = number1.toString().replace(/(\.\d*?)0+$/, "$1");

    // If the decimal is only ".", remove it
    if (formatted.endsWith(".")) {
      formatted = formatted.slice(0, -1);
    }

    return formatted;
  }
};

export default numberFormat;
