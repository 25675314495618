import React, { useState, useEffect } from "react";
import { Modal, Button, Flex, Table, Spin, Input } from "antd";
import { useTranslation } from "react-i18next";
import API_URL from "../../../config/api";
import config from "../../../config/configapi";
import OpenNotification from "../../../Toast/OpenNotification";
import { LazyLoadImage } from "react-lazy-load-image-component";
import imageEmpty from "../../../image/emptyimg.png";
import { SearchOutlined } from "@ant-design/icons";

export const AllGameModal = ({
  isModalGame,
  showModal,
  setDataSource,
  setSelectGame,
  initialSelectedGames = [],
}) => {
  const { t } = useTranslation();
  const [allGame, setAllGame] = useState([]); // เกมทั้งหมดจาก API
  const [displayedGames, setDisplayedGames] = useState([]); // เกมที่แสดงในตาราง
  const [loading, setLoading] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedGames, setSelectedGames] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredInfo, setFilteredInfo] = useState({});
  const isMobile = window.innerWidth < 768 ? true : false;
  const URL_HOST = `${config.API_SERVER}`;

  // โหลดข้อมูลเกมทั้งหมดและตั้งค่าเริ่มต้น
  useEffect(() => {
    get_AllGame();
  }, []);

  // ตั้งค่าเริ่มต้นสำหรับเกมที่เคยเลือกไว้
  useEffect(() => {
    if (initialSelectedGames.length > 0) {
      setSelectedRowKeys(initialSelectedGames.map((game) => game.id));
      setSelectedGames(initialSelectedGames);
    }
  }, [initialSelectedGames]);

  // อัพเดทรายการเกมที่แสดงเมื่อมีการ search หรือ filter
  useEffect(() => {
    updateDisplayedGames();
  }, [allGame, searchText, filteredInfo]);

  const get_AllGame = async () => {
    setLoading(true);
    try {
      const response = await API_URL.get(`api/preset/allgame`);
      const games = response.data;
      if (games.length > 0) {
        setAllGame(games);
        setDisplayedGames(games);
      }
    } catch (err) {
      OpenNotification({ message: `${t("an_error_occurred")}`, type: 4 });
    }
    setLoading(false);
  };

  // ฟังก์ชันอัพเดทรายการเกมที่แสดง
  const updateDisplayedGames = () => {
    let filtered = [...allGame];

    // กรองตามการค้นหา
    if (searchText) {
      filtered = filtered.filter((game) =>
        game.gamename.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    // กรองตาม camp
    if (filteredInfo.campname && filteredInfo.campname.length > 0) {
      filtered = filtered.filter((game) =>
        filteredInfo.campname.includes(game.camp.campname)
      );
    }

    // รวมเกมที่เลือกไว้แล้วแต่อาจถูกกรองออก
    const selectedNotInFiltered = selectedGames.filter(
      (selected) => !filtered.find((game) => game.id === selected.id)
    );

    filtered = [...filtered, ...selectedNotInFiltered];

    setDisplayedGames(filtered);
  };

  function getRandomTime() {
    // สุ่มชั่วโมง 0 - 23
    const hours = Math.floor(Math.random() * 24);

    // สุ่มนาที 0 - 59
    const minutes = Math.floor(Math.random() * 60);

    // แปลงตัวเลขให้อยู่ในรูปแบบ 2 หลัก (เช่น 09, 00)
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");

    // รวมเวลาในรูปแบบ HH:mm
    return `${formattedHours}:${formattedMinutes}`;
  }

  const onSelectChange = (newSelectedRowKeys, selectedRows) => {
    setSelectedRowKeys(newSelectedRowKeys);

    // อัพเดทเกมที่เลือกโดยเก็บ startTime และ endTime เดิม และเพิ่ม order
    const updatedSelectedGames = selectedRows.map((newGame, index) => {
      const existingGame = selectedGames.find((game) => game.id === newGame.id);
      if (existingGame) {
        return {
          ...newGame,
          order: index + 1, // เพิ่ม order โดยเริ่มจาก 1
          // startTime: existingGame.startTime || getRandomTime(),
          // endTime: existingGame.endTime || getRandomTime(),
          startTime: existingGame.startTime || "",
          endTime: existingGame.endTime || "",
        };
      }
      return {
        ...newGame,
        order: index + 1, // เพิ่ม order โดยเริ่มจาก 1
        // startTime: getRandomTime(),
        // endTime: getRandomTime(),
        startTime: "",
        endTime: "",
      };
    });

    setDataSource(updatedSelectedGames);
    setSelectedGames(updatedSelectedGames);
  };

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const handleChange = (pagination, filters) => {
    setFilteredInfo(filters);
  };

  const clearFilters = () => {
    setFilteredInfo({});
    setSearchText("");
  };

  const getCampFilters = () => {
    const camps = [...new Set(allGame.map((game) => game.camp.campname))];
    return camps.map((camp) => ({ text: camp, value: camp }));
  };

  const SelectGame_confirm = () => {
    // const gamesWithOrder = selectedGames.map((game, index) => {
    //   // หาเกมที่เคยเลือกไว้จาก initialSelectedGames
    //   const existingGame = initialSelectedGames.find(init => init.id === game.id);
    //   return {
    //     ...game,
    //     order: index + 1,
    //     // ถ้าเป็นเกมที่เคยเลือกไว้ ให้ใช้ค่าเวลาเดิม ถ้าไม่ใช่ให้เป็นค่าว่าง
    //     startTime: existingGame ? existingGame.startTime : "",
    //     endTime: existingGame ? existingGame.endTime : ""
    //   };
    // });

    // setSelectGame(gamesWithOrder);
    // setDataSource(gamesWithOrder);

    showModal();
  };

  const columns = [
    {
      title: `${t("icon")}`,
      width: "10%",
      dataIndex: "icongame",
      render: (icongame) => (
        <LazyLoadImage
          className="h-16 w-full object-contain m-0 rounded-md"
          src={
            `${URL_HOST}${icongame}` !== null
              ? `${URL_HOST}${icongame}`
              : null && icongame !== null
              ? `${URL_HOST}${icongame}`
              : imageEmpty
          }
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = imageEmpty;
          }}
        />
      ),
    },
    {
      title: `${t("image")}`,
      width: "15%",
      dataIndex: "imagegame",
      render: (imagegame) => (
        <LazyLoadImage
          className="h-16 w-full object-contain m-0 rounded-md"
          src={
            `${URL_HOST}${imagegame}` !== null
              ? `${URL_HOST}${imagegame}`
              : null && imagegame !== null
              ? `${URL_HOST}${imagegame}`
              : imageEmpty
          }
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = imageEmpty;
          }}
        />
      ),
    },
    {
      title: `${t("game_name")}`,
      dataIndex: "gamename",
      width: "20%",
      render: (gamename) => (
        <p className="text-sm font-semibold my-auto truncate">{gamename}</p>
      ),
    },
    {
      title: `${t("game_camp_name")}`,
      dataIndex: ["camp", "campname"],
      key: "campname",
      filters: getCampFilters(),
      filteredValue: filteredInfo.campname || null,
      render: (text, record) => (
        <p className="text-sm font-semibold my-auto truncate">
          {record.camp.campname}
        </p>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    preserveSelectedRowKeys: true, // เพิ่มตัวเลือกนี้เพื่อรักษาการเลือกไว้
  };

  return (
    <Modal
      style={{ top: 10 }}
      title={`${t("all_games")}`}
      open={isModalGame}
      onOk={showModal}
      onCancel={showModal}
      width={isMobile ? "100%" : "60%"}
      footer={[
        <div key="footer" className="text-center flex justify-center">
          {/* <button
            onClick={SelectGame_confirm}
            className="text-center text-white font-semibold px-10 py-2 rounded-md bg-purple-500 hover:bg-purple-800 shadow-md"
          >
            {t("select")}
          </button> */}
          {/* <div className="mx-5 md:mx-16"></div> */}
          <button
            onClick={showModal}
            className="bg-transparent hover:bg-gray-100 text-black font-semibold py-2 px-10 border border-purple-500 hover:border-transparent rounded"
          >
            {t("close")}
          </button>
        </div>,
      ]}
    >
      <div className="w-full py-4">
        <Flex gap="middle" vertical>
          <Flex justify="space-between" align="center">
            <Input
              placeholder={`${t("search_game_name")}`}
              prefix={<SearchOutlined />}
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
              className="mb-4 max-w-md"
            />
            <Button onClick={clearFilters} className="mb-4">
              Clear Filters
            </Button>
          </Flex>

          <Flex align="center" gap="middle">
            {selectedRowKeys.length > 0
              ? `Selected ${selectedRowKeys.length} items`
              : null}
          </Flex>

          <Table
            rowSelection={rowSelection}
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={displayedGames}
            onChange={handleChange}
            loading={{
              indicator: (
                <div>
                  <Spin size="large" />
                </div>
              ),
              spinning: loading,
            }}
          />
        </Flex>
      </div>
    </Modal>
  );
};
