
import axios from 'axios';

export const getClientIP = async () => {
  let ipdata = { data: { ip: '' } };
  
  try {
    ipdata = await axios.get("https://api.ipify.org/?format=json");
  } catch (error) {
    try {
      const response = await axios.get("https://ipapi.co/json/");
      ipdata = { data: { ip: response.data.ip } };
    } catch {
      console.error("Cannot get client IP");
    }
  }
  
  return ipdata.data.ip;
};