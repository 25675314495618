import React from "react";
import { Table, Tag,Spin } from "antd";
import { useState, useEffect } from "react";

import { TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";

import numberFormat from "../../../../Function/NumberFormat2float";
import { useWebSocket } from "../../WebSocketContext";
import API_URL from "../../../../config/api";
import OpenNotification from "../../../../Toast/OpenNotification";
import { useTranslation } from "react-i18next";

function CreditList({id}) {
  const { t } = useTranslation();
  const { websocket } = useWebSocket();
  const [allcredit, setAllcredit] = useState([]);
  const [allcreditBackup, setAllcreditBackup] = useState([]);
  const [loading, setLoading] = useState(true);
  const [inputSearch, setInputSearch] = useState("");

  const [timestart, setTimestart] = useState(
    dayjs().startOf("month").format("YYYY-MM-DD")
  );
  const [timeend, setTimeend] = useState(
    dayjs().endOf("month").format("YYYY-MM-DD")
  );
  const [sumDeposit, setSumDeposit] = useState(0);
  const [sumWithdraw, setSumWithdraw] = useState(0);



  useEffect(() => {
    get_AllCreditAdmin();
  }, []);

  useEffect(() => {
    if (websocket) {
      websocket.onmessage = (message) => {
        const data = JSON.parse(message.data);

        if (data.type === "refreshCredits") {
          get_AllCreditAdmin();
        }
      };
    }

    return () => {
      if (websocket) {
        websocket.onmessage = null;
      }
    };
  }, [websocket]);

  const get_AllCreditAdmin = async () => {
    setAllcredit([]);
    setAllcreditBackup([]);
    let sumde = 0;
    let sumwith = 0;
    setLoading(true);
    await API_URL.get(`api/creditadmin/AllCreditadminbyUser/${id}`)
      .then((res) => {
        const getalluser = res.data;
        if (getalluser.length !== 0) {
          getalluser.map((e) => {
            e.amountxt = e.amount.toString();
            e.createdAttxt = dayjs(e.createdAt)
              .format("DD/MM/YYYY HH:mm")
              .toString();
            if (e.credittype === 1) sumde += e.amount;
            if (e.credittype === 2) sumwith += e.amount;
          });
          setSumDeposit(sumde);
          setSumWithdraw(sumwith);
          setAllcredit(getalluser);
          setAllcreditBackup(getalluser);
        }
      })
      .catch((err) => {
        OpenNotification({ message: `${t("an_error_occurred")}`, type: 4 });
      });
    setLoading(false);
  };



  const columns_alluser = [


    {
      title: `${t("credit_add_reduce_type")}`,
      align: "center",
      dataIndex: "credittype",
      render: (credittype) => (
        <>
          {credittype === 1 ? (
            <Tag color="green">
              <p className="text-xs text-black my-auto px-2 py-1 font-semibold">
                {t("increase")}
              </p>
            </Tag>
          ) : (
            <Tag color="volcano">
              <p className="text-xs text-black my-auto px-2 py-1 font-semibold">
                {t("reduce")}
              </p>
            </Tag>
          )}
        </>
      ),
      filters: [
        { text: `${t("increase")}`, value: 1 },
        { text: `${t("reduce")}`, value: 2 },
      ],
      onFilter: (value, record) => {
        return record.credittype === value;
      },
    },
    {
      title: `${t("credit")}`,
      align: "center",
      render: (allcredit) => (
        <p className="text-xs text-black my-auto px-2">
          {numberFormat(allcredit.amount)}
        </p>
      ),
    },
    {
      title: `${t("original_amount")}`,
      align: "center",
      render: (allcredit) => (
        <p className="text-xs text-black my-auto px-2">
          {numberFormat(allcredit.preamount)}
        </p>
      ),
    },
    {
      title: `${t("balance")}`,
      align: "center",
      render: (allcredit) => (
        <Tag color="green">
          <p className="text-xs text-black my-auto px-2 py-1">
            {allcredit.credittype === 1 ? (
              <>{numberFormat(allcredit.amount + allcredit.preamount)}</>
            ) : (
              <>{numberFormat(allcredit.preamount - allcredit.amount)}</>
            )}
          </p>
        </Tag>
      ),
    },
    {
      title: `${t("note")}`,
      width: "15%",
      ellipsis: {
        showTitle: true,
      },
      render: (allcredit) => (
        <p className="text-xs text-black my-auto px-2">
          {allcredit.note === "" ? "-" : allcredit.note}
        </p>
      ),
    },
    {
      title: "Admin",
      width: "10%",
      align: "center",
      ellipsis: {
        showTitle: true,
      },
      render: (allcredit) => (
        <p className="text-xs text-black my-auto px-2">{allcredit.user.name}</p>
      ),
    },
    {
      title: `${t("time")}`,
      align: "center",
      render: (allcredit) => (
        <p className="text-xs text-black my-auto px-2">
          {dayjs(allcredit.createdAt).format("DD/MM/YYYY HH:mm")}
        </p>
      ),
    },
  ];

  const timeFormat = (time) => {
    return dayjs(time).format("YYYY-MM-DD");
  };

  const changDatestart = (e) => {
    if (e.target.value >= timeend) {
      setTimestart(dayjs(timeend).add(-1, "day").format("YYYY-MM-DD"));
    } else {
      setTimestart(e.target.value);
    }
  };
  const changDateend = (e) => {
    if (e.target.value <= timestart) {
      setTimeend(dayjs(timestart).add(1, "day").format("YYYY-MM-DD"));
    } else {
      setTimeend(e.target.value);
    }
  };

  const handleChageByDate = () => {
    let data = [];
    let sumde = 0;
    let sumwith = 0;
    allcreditBackup.map((all) => {
      if (inputSearch.length === 0) {
        if (
          timeFormat(all.createdAt) >= timestart &&
          timeFormat(all.createdAt) <= timeend
        ) {
          data.push(all);
          if (all.credittype === 1) sumde += all.amount;
          if (all.credittype === 2) sumwith += all.amount;
        }
      } else {
        if (
          timeFormat(all.createdAt) >= timestart &&
          timeFormat(all.createdAt) <= timeend &&
          all.people.uid === inputSearch
        ) {
          data.push(all);
          if (all.credittype === 1) sumde += all.amount;
          if (all.credittype === 2) sumwith += all.amount;
        }
      }
    });
    setSumDeposit(sumde);
    setSumWithdraw(sumwith);
    OpenNotification({
      message: `${t("search_completed")}`,
      type: 1,
    });

    setAllcredit(data);
  };

  return (
    <div>
      <div className="my-3">
      <p className="text-center text-base text-black">{t("search_completed")}</p>
      <div className="h-0.5 bg-gray-600 w-full mb-4"></div>
        <div className="w-full md:flex justify-start my-1">
          {/* <div className="w-full md:w-1/3   md:flex">
            <TextField
              className="bg-white rounded-md"
              name="password"
              size="small"
              color="secondary"
              id="outlined-textarea"
              autoComplete="off"
              label="Username,เบอร์โทร"
              InputProps={{
                endAdornment: <SearchIcon position="end"></SearchIcon>,
              }}
              fullWidth
              value={inputSearch}
              onChange={(e) => {
                const currValue = e.target.value;
                setInputSearch(currValue);
                const filteredData = allcreditBackup.filter((entry) =>
                  entry.people.username
                    .toLowerCase()
                    .includes(currValue.toLowerCase())||
                  entry.people.phone
                    .toLowerCase()
                    .includes(currValue.toLowerCase())
                );
                setAllcredit(filteredData);
              }}
            />
          </div> */}

          <div className="w-full lg:w-2/3 flex flex-wrap my-4 mx-0 md:mx-3 md:my-0">
            <div className="w-1/2 md:w-1/5 px-1 bg-white rounded-md">
              <TextField
                id="date"
                label={t("start")}
                type="date"
                size="small"
                value={timestart}
                onChange={(e) => changDatestart(e)}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
              />
            </div>
            <div className="w-1/2 md:w-1/5 px-1 bg-white rounded-md">
              <TextField
                id="date"
                label={t("end")}
                type="date"
                size="small"
                fullWidth
                value={timeend}
                onChange={(e) => changDateend(e)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <button
              onClick={handleChageByDate}
              className="w-full lg:w-1/12 my-1 md:my-0 px-4 py-2 bg-green-600 hover:bg-green-800 rounded-md shadow-lg text-white wsn"
            >
              {t("search")}
            </button>
          </div>
        </div>

        <Table
          className="table-striped-rows"
          size="middle"
          scroll={{
            x: 900,
          }}
          pagination={{ pageSize: 15 }}
          rowKey="id"
          columns={columns_alluser}
          dataSource={allcredit}
          loading={{
            indicator: (
              <div>
                <Spin size="large" />
              </div>
            ),
            spinning: loading,
          }}
        />
        <div className="w-full flex justify-end my-2">
          <div className="w-full lg:w-1/4 bg-slate-200 px-5  py-2 divide-y divide-gray-400 rounded-lg text-base truncate">
            <div className="flex justify-between my-2">
              <p>{t("total_credit_added")} :</p>
              <p className="font-semibold">฿ {numberFormat(sumDeposit)}</p>
            </div>
            <div className="flex justify-between my-2">
              <p>{t("total_credit_reduction")} :</p>
              <p className="font-semibold">฿ {numberFormat(sumWithdraw)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreditList;
