import React from "react";
import { useParams, useNavigate } from "react-router-dom";
// import logoweb from "../../../icon/Logoeorder.webp";

import API_URL from "../../../../config/api";

import bg from "../../../../image/bg2.jpg";
import Adduser from "../Adduser";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Button } from "@mui/material";
import { useState, useEffect } from "react";
import authHeader from "../../../../services/auth-header";

import { useCookies } from "react-cookie";
import numberFormat from "../../../../Function/NumberFormat2float";

import config from "../../../../config/configapi";
import CreditList from "./CreditList";
import { useTranslation } from "react-i18next";

function Userdetail({ getpeopleId }) {
  const { t } = useTranslation();
  const URL_HOST = `${config.API_SERVER}`;
  let { peopleId } = useParams() || getpeopleId;
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [userdata, setUserdata] = useState(null);

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [username, setUsername] = useState("");
  const [getId, setGetId] = useState(null);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [codebank, setCodebank] = useState("");
  const [idbank, setIdbank] = useState(0);
  const [addressnow, setAddressnow] = useState("");
  const [status, setStatus] = useState(0);
  const [imageFrontCard, setImageFrontCard] = useState(null);
  const [imageBackCard, setImageBackCard] = useState(null);

  const [alltrade, setAlltrade] = useState([]);

  const [refcode, setRefcode] = useState("");

  const [isModalUser, setIsModalUser] = useState(false);

  const [credit, setCredit] = useState(0);

  const [openTabCK, setOpenTabCK] = useCookies(["openTabCK"]);
  const [openTab, setOpenTab] = useState(Number(openTabCK.openTabCK) || 1);

  useEffect(() => {
    // setOpenTab( Number(openTabCK.openTabCK));
    authHeader();

    if (peopleId != null) {
      get_Oneuser();
    }
  }, []);

  const get_Oneuser = async () => {
    setUserdata(null);
    setLoading(true);

    await API_URL.get(`api/people/getOneUserAdmin/${peopleId}`)
      .then(async (res) => {
        const findone = res.data.user;
        setUsername(findone.username);
        setRefcode(findone.refcode);
        setUserdata(findone);
        setFirstname(findone.firstname);
        setLastname(findone.lastname);
        setIdbank(findone.idbank);
        setCodebank(findone.codebank);
        setGetId(findone.id);
        setPhone(findone.phone);
        setEmail(findone.email);
        setCredit(findone.credit);
        setAddressnow(findone.addressnow);
        setStatus(findone.verify_status);
        setImageFrontCard(`${URL_HOST}${findone.imagefrontcard}`);
        setImageBackCard(URL_HOST + findone.imagebackcard);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };

  const showModalUser = () => {
    setIsModalUser(!isModalUser);
    if (isModalUser === true) {
      setGetId(null);
    }
  };

  const showModalEdit = () => {
    showModalUser();
  };

  return (
    <div
      style={{
        backgroundImage: `url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
        minHeight: "100vh",
        height: "100%",
      }}
    >
      {isModalUser ? (
        <Adduser
          isModalUser={isModalUser}
          showModalUser={showModalUser}
          get_Oneuser={get_Oneuser}
          findone={userdata}
          getId={peopleId}
        />
      ) : (
        <></>
      )}
      <nav className="flex items-center justify-between h-14  bg-gray-800 px-6 py-3 md:py-5 lg:py-2  w-full z-30 drop-shadow-xl max-h-20">
        <div className="w-2/4 md:w-1/3 ">
          <Button
            variant="contained"
            color="warning"
            startIcon={<ArrowBackIosNewIcon />}
            onClick={() => navigate(-1)}
            className=""
          >
            {t("back")}
          </Button>
        </div>
        <div className="flex items-center justify-center flex-shrink-0 text-white mr-6 w-2/4 md:w-1/3  ">
          {/* <img src={logoweb} alt="headerlogo" className="h-20" /> */}
        </div>

        <div className="w-1/12 md:w-1/3 flex justify-end"></div>
      </nav>
      <div className="mt-5 w-full md:flex px-2 md:px-7">
        <div className="w-full mx-auto p-2 md:p-4 bg-zinc-100 rounded-xl shadow-md shadow-gray-400">
          <div className="flex items-center mb-2">
            <div className=" w-2/3 mb-3 px-1">
              <div className="flex items-center justify-start my-1 text-sm lg:text-base whitespace-nowrap">
                <p className="font-semibold my-0 truncate">Username :&nbsp;</p>
                <p className="my-0">{username}</p>
              </div>
              <div className="flex items-center justify-start my-1 text-sm lg:text-base">
                <p className="font-semibold my-0 "> {t("phone")} :&nbsp; </p>
                <p className="my-0">{phone}</p>
              </div>
            </div>
          </div>

          <div className="flex justify-center">
            <button
              onClick={showModalEdit}
              className="text-center text-white font-semibold my-0 w-4/6 md:w-3/6  px-10 py-1 rounded-md bg-orange-500 hover:bg-orange-800 shadow-md"
            >
              {t("edit_information")}
            </button>
          </div>
        </div>

        <div className="w-full mx-auto">
          <div className="my-0  mx-1 md:mx-8 grid grid-cols-1 lg:grid-cols-2 ">
            <div className="p-0.5 ">
              <div className="pl-1 pr-1  w-full   bg-green-500  rounded-lg shadow-md mt-2 lg:mt-0">
                <div className="flex w-full h-full py-2 px-4 bg-white rounded-lg justify-between">
                  <div className="my-auto truncate mx-auto">
                    <p className="font-medium text-sm lg:text-lg my-auto text-center">
                      {t("credit")}
                    </p>
                    <div className="flex text-center mx-auto justify-center">
                      <p className="text-base lg:text-xl my-auto text-center">
                        {numberFormat(credit)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/********************************************************/}
          </div>
        </div>
      </div>
      <div className="w-full pt-3">
        <div className="w-full px-1 md:px-8">
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
            <div className="px-4 py-5 flex-auto">
              <div className="tab-content tab-space">
                <div className="block">
                  <CreditList id={peopleId} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Userdetail;
