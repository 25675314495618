import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import EN_img from "../icon/en_icon.png";
import TH_img from "../icon/th_icon.png";
import MM_img from "../icon/mm_icon.png";
import {

    Select,
    MenuItem,
  } from "@mui/material";
const languages = [
  { code: "en", lang: "English", img: EN_img },
  { code: "th", lang: "ไทย", img: TH_img },
];

function LanguageSelectorAdmin() {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [language, setLanguage] = useState(i18n.language === "th"
    ? "th"
    : i18n.language === "en"
    ? "en"
    : "mm");

  const changeLanguage = (lng) => {
    setLanguage(lng)
    i18n.changeLanguage(lng);
    localStorage.setItem(`i18nextLngAdmin`, lng);
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative">
    <button
      onClick={() => setIsOpen(!isOpen)}
      className="inline-flex my-auto px-2 py-1 md:px-4 md:py-2 rounded-md lg:rounded-lg items-center align-middle justify-center w-full border border-gray-700 shadow-sm bg-zinc-900 text-sm font-medium text-gray-700 hover:bg-zinc-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-zinc-700"
    >
      <img
        src={language === "th" ? TH_img : language === "en" ? EN_img : MM_img}
        alt={language}
        className="h-7 md:h-7"
      />
      <svg
        className="-me-1 ms-2 h-5 w-5 text-gray-400"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fillRule="evenodd"
          d="M10.293 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L10 12.586l3.293-3.293a1 1 0 011.414 1.414l-4 4z"
          clipRule="evenodd"
        />
      </svg>
    </button>
    {isOpen && (
      <div
        className="absolute mt-1 lg:mt-2 right-0 w-28 lg:w-32 rounded-md shadow-lg bg-gray-800 ring-1 ring-black ring-opacity-5 z-50"
        role="menu"
        aria-orientation="vertical"
      >
        <div className="py-1" role="none">
          {languages.map((lang) => (
            <button
              key={lang.code}
              onClick={() => changeLanguage(lang.code)}
              className="flex items-center w-full px-4 py-3 text-sm text-white hover:bg-gray-700"
              role="menuitem"
            >
              <img src={lang.img} alt={lang.lang} className="h-5 w-5 mr-3 lg:h-7 lg:w-7" />
              <span>{lang.lang}</span>
            </button>
          ))}
        </div>
      </div>
    )}
  </div>
    // <Select
    // sx={{ backgroundColor:'#fff' }}
    //   name="language"
    //   size="small"
    //   className="flex cus_selet"
    //   defaultValue={language}
    //   value={language}
    //   onChange={(e) => changeLanguage(e.target.value)}
    //   inputProps={{ 'aria-label': 'Without label' ,style: { display: "grid", whiteSpace: "nowrap",gridTemplateColumns:"1fr 1fr",backgroundColor:"#fff" },}}
    //   fullWidth
    // >
    //   <MenuItem value={"th"} className="flex align-middle justify-center py-1">
    //     <img src={TH_img} className="h-5 mr-1" />
    //    <p>TH</p> 
    //   </MenuItem>
    //   <MenuItem value={"en"} className="flex align-middle justify-center py-1">
    //     <img src={EN_img} className="h-5 mr-1" />
    //     <p>EN</p>
    //   </MenuItem>

    // </Select>
  );
}

export default LanguageSelectorAdmin;
