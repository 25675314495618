import React from 'react';
import Slider from "react-slick";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import imageEmpty from "../../../image/emptyimg.png";

const ImageSlideshow = ({ allGuide, URL_HOST }) => {
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  arrows: false,
  autoplaySpeed: 3000,
  centerMode: true,  // เพิ่มบรรทัดนี้
  centerPadding: '60px',  // ปรับตามต้องการ
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        centerPadding: '40px',
    
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        centerPadding: '20px',
  
      }
    }
  ]
};

  if (allGuide.length === 0) {
    return null;
  }
  const repeatedGuides = allGuide.length < 3
  ? [...allGuide, ...allGuide, ...allGuide].slice(0, 3)
  : allGuide;

  return (
    <div className="w-full max-w-screen-2xl mx-auto">
      <Slider {...settings}>
        {repeatedGuides.map((guide, index) => (
          <div key={index} className="px-2">
            <div className="aspect-w-16 aspect-h-9">
              <LazyLoadImage
                className="w-full h-full object-cover rounded-lg pointer-events-none"
                src={`${URL_HOST}${guide.imagebanner}`}
                alt={`Slide ${index + 1}`}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = imageEmpty;
                }}
                draggable="false"
              />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageSlideshow;