import i18n from 'i18next'
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector'
if (!localStorage.getItem('i18nextLng')) {
    localStorage.setItem('i18nextLng', 'mm');
}

const i18nUser = i18n.createInstance();
i18nUser.use(LanguageDetector).use(initReactI18next).init({
    // debug: true,
    fallbackLng: "mm",
    // lng: 'en',
    detection: {
        lookupLocalStorage: 'i18nextLng',
    },
    resources: {
        en: {
            translation: {
                contact: "Contact",
                contactus: "Contact us",
                home: "Home",
                trade: "Trade",
                trade_history: "Trade History",
                transaction_history: "Transaction History",
                info: "Info",
                wallet: "Wallet",
                verify_identity: "Verify identity",
                not_verify: "Not Verify",
                verify_successfully: "Identity confirmed successfully",
                fail_verify: "Failed to verify identity.Try again.",
                waiting_verify: "Waiting to confirm identity",
                edit_profile: "Edit profile",
                view: "View",
                trading_list: "Trading List",
                contact_admin: "Contact Admin",
                imgnotmore20mb: "Image size must not exceed 20 MB.",
                enter_complete_info: "Enter complete information",
                data_sent_successfully: "Data sent successfully",
                status: "Status",
                select_bank: "Select a Bank",
                account_no: "Account No",
                upload_front_card: "Upload Front of ",
                upload_back_card: "Upload Back of ",
                select_image_file: "Select image file",
                send_for_inspection: "Send for inspection",
                passwordisincorrect: "Password is incorrect",
                newpasswordnotmatch: "New passwords do not match",
                password8char: "Password must be more than 8 characters",
                changepasswordsuccess: "Change password successful",
                fillintheinfocom: "Fill in the information completely",
                data_edit_sucess: "Data edit successful",
                thisphoneinuse: "This Phone number is already in use",
                firstname: "Firstname",
                lastname: "Lastname",
                name: "Name",
                phone_craditcard: "Phone",
                email: "E-mail",
                address: "Address",
                changeinfo: "Change infomation",
                changepassword: "Change password",
                confirmpassword: "Confirm Password",
                newpassword: "New password",
                confirmnewpassword: "Confirm new password",
                view_all_cryptocurrency: "View All cryptocurrency",
                enteramount: "Enter amount",
                notenoughmoney: "Not enough money",
                choosehighlow: "Choose High/Low Options",
                purchased: "Purchased",
                graph: "Graph",
                amount: "Amount",
                minute: "min",
                higher: "Higher",
                lower: "Lower",
                buy: "Buy",
                sell: "Sell",
                confirm: "Confirm",
                finishedsearch: "Finished search",
                start: "Start",
                end: "End",
                search: "Search",
                waiting: "Waiting",
                approve: "Approve",
                notapproved: "Not approved",
                time: "Time",
                oldamount: "Old amount",
                balance: "Balance",
                inprogress: "In progress",
                waitingforadminapproval: "Waiting for Admin approval",
                insufficientwithdrawalamount: "Insufficient withdrawal amount",
                withdrawalspendingapproval: "There is a list of withdrawals pending approval",
                confirm_withdrawal: "Confirm withdrawal",
                cancel: "Cancel",
                amount_to_withdraw: "Amount to withdraw",
                minimum: "Minimum",
                withdrawal_history: "Withdrawal history",
                deposit_history: "Deposit history",
                enter_the_correct_amount: "Enter the correct amount",
                nobankaccount: "No bank account",
                linkyourbankaccount: "Please link your bank account to withdraw",
                gotobank: "Go to your bank account page",
                nodata: "No data",
                no_list: "No.",
                stocks: "Stocks",
                order_type: "Order type",
                opening_time: "Opening time",
                opening_price: "Opening price",
                closing_time: "Closing time",
                closing_price: "Closing price",
                waiting_trade: "Waiting",
                result: "Result",
                tradingnow: "Trading now",
                coin_list: "Coin list",
                close: "Close",
                buy_alert: "Buy",
                fillinyourbank: "Fill in your bank account correctly",
                note: "Note",
                selectedcoins: "Select",
                register: "REGISTER",
                pleaseenterpasswordandcharacter: "Please enter a password and have at least 8 characters",
                passwordnotmatch: "Password not match",
                pleaseloginagain: "Please Login again",
                successfullyregisterpleaselogin: "Successfully registered. Please log in.",
                footertxt1: "Our website was established in February 2018 as a next-generation exchange platform for trading digital assets. We offer exceptional services to the public, enabling seamless buying, selling, and storage of digital assets according to individual needs. Our company is legally registered with a capital of 450 million baht and has its headquarters located in Bangkok, Thailand.",
                footertxt2: "Cryptocurrencies and digital tokens carry high risks. You could lose your entire investment. Please make sure to research and invest according to your risk tolerance.",
                warning: "Warning",
                profitability: "Profitability",
                ontherise: "on the rise",
                letstarttradingonweb: "Let's start trading on our web app",
                starttradingnow: "Start trading now",
                thelastestfromourbroker: "The latest from our broker",
                newfeatures_lastestwebsiteandmore: "New features, latest website and more...",
                secure_account: "SECURE ACCOUNT",
                now_your_fingerprint: "You can now use your fingerprint to access our Web App from everywhere",
                market_analysis: "MARKET ANALYSIS",
                understanding_support: "Understanding Support and Resistance Levels",
                risk_management: "RISK MANAGEMENT",
                masteringthetworule: "Mastering the 2% rule in trading",
                education: "EDUCATION",
                howtostarttradingonplatform: "How to start trading on the platform?",
                anniversary: "ANNIVERSARY",
                ourbrokerrevolutionizing: "Our broker has been revolutionizing the industry for the past 5 years!",
                smoothexperienceonanydevice: "Smooth experience on any device",
                whetheryouprefertrading: "Whether you prefer trading at your desk or on the go -- Bitcoinsv-optionex has got you covered",
                custombuiltplatform: "The custom built platform has been adapted to any device you may choose and switching is 100% seamless",
                teamofexpert: "TEAM OF EXPERT",
                whyus: "Why us?",
                easytrading: "Easy trading",
                ui_with_realtime: "User-friendly interface with real-time market data and one-click trading. Perfect for beginners and experienced traders alike",
                learnmore: "Learn more",
                safeandsecure: "Safe & Secure",
                advancedencryption: "Advanced encryption and two-factor authentication protect your funds and data. We adhere to strict regulatory standards",
                customizable: "Customizable",
                personalize_your_trading_environment: "Personalize your trading environment with custom charts,indicators, and alerts. Adapt the platform to your trading style",
                enterthenewear: "ENTER THE NEW ERA",
                ofinvesting: "OF INVESTING",
                depositviaadmin: "Deposit via admin",
                aboutus: "About us",
                expiry: "Expiry",
                cardtype: "Card type",
                idcard: "ID card",
                passport: "Passport",
                ssncard: "SSN card",
                driverlicense: "Driver's license card",
                forex_open_date: "The Forex market is open from Monday at 4 A.M. to Saturday at 4 A.M.",
                profit: "Profit",

                gamecamp: "Game Camp",
                comingsoon: "Coming soon",
                not_enough_credit: "Not enough credit",
                timeup: "Time's up",
                use: "use",
                credit: "Credit",
                recommendedgame: "Recommended Games",
                data_transmission_error: "An error occurred",
                login: "LOGIN",
                phoneorpasswordincorrect: "Phone number/username or password is incorrect",
                fillcompletephonenumber: "Fill in complete information",
                phone: "Phone number",
                password: "Password",
                chatwithus: "Chat with us",
                enter_to_play: "Play",
                logout: "Logout",
                language_chat: "en",
                back: "Back",
                game: "Game",
                bonustime: "Bonus Time"

            }
        }, th: {
            translation: {
                contact: "ติดต่อ",
                contactus: "ติดต่อเรา",
                home: "หน้าหลัก",
                trade: "เทรด",
                trade_history: "ประวัติการเทรด",
                transaction_history: "ประวัติฝาก-ถอน",
                info: "ข้อมูลส่วนตัว",
                wallet: "กระเป๋า",
                verify_identity: "ยืนยันตัวตน",
                not_verify: "ไม่ได้ยืนยันตัวตน",
                verify_successfully: "ยืนยันตัวตนเรียบร้อย",
                fail_verify: "ตรวจสอบล้มเหลว โปรดลองใหม่",
                waiting_verify: "รอดำเนินการยืนยันตัวตน",
                edit_profile: "แก้ไขโปรไฟล์",
                view: "ดู",
                trading_list: "รายการเทรด",
                contact_admin: "ติดต่อแอดมิน",
                imgnotmore20mb: "ขนาดรูปภาพต้องไม่เกิน 20 MB",
                enter_complete_info: "กรอกข้อมูลให้ครบถ้วน",
                data_sent_successfully: "ส่งข้อมูลสำเร็จ",
                status: "สถานะ",
                select_bank: "เลือกธนาคาร",
                account_no: "หมายเลขบัญชี",
                upload_front_card: "อัพโหลด (ด้านหน้า)",
                upload_back_card: "อัพโหลด (ด้านหลัง)",
                select_image_file: "เลือกไฟล์รูปภาพ",
                send_for_inspection: "ส่งตรวจสอบ",
                passwordisincorrect: "รหัสผ่านไม่ถูกต้อง",
                newpasswordnotmatch: "รหัสผ่านใหม่ไม่ตรงกัน",
                password8char: "รหัสใหม่ต้องมากกว่า 8 ตัวอักษร",
                changepasswordsuccess: "เปลี่ยนรหัสผ่านเรียบร้อย",
                fillintheinfocom: "กรอกข้อมูลให้ครบถ้วน",
                data_edit_sucess: "แก้ไขข้อมูลเรียบร้อย",
                thisphoneinuse: "เบอร์โทรนี้ถูกใช้แล้ว",
                firstname: "ชื่อ",
                lastname: "นามสกุล",
                name: "ชื่อ",
                phone_craditcard: "เบอร์โทร",
                expiry: "หมดอายุ",
                email: "อีเมล",
                address: "ที่อยู่",
                changeinfo: "แก้ไขข้อมูล",
                changepassword: "แก้ไขรหัสผ่าน",
                confirmpassword: "ยืนยันรหัสผ่าน",
                newpassword: "รหัสผ่านใหม่",
                confirmnewpassword: "ยืนยันรหัสผ่านใหม่",
                view_all_cryptocurrency: 'เหรียญทั้งหมด',
                enteramount: "กรอกจำนวนให้ถูกต้อง",
                notenoughmoney: "จำนวนเงินในกระเป๋าไม่พอ",
                choosehighlow: 'เลือกขึ้น หรือ ลง',
                purchased: "ซื้อเรียบร้อย",
                graph: "กราฟ",
                amount: "จำนวน",
                minute: "นาที",
                higher: "ขึ้น",
                lower: "ลง",
                buy: "ซื้อ",
                sell: "ขาย",
                confirm: "ยืนยันการซื้อ",
                finishedsearch: "ค้นหาเสร็จสิ้น",
                start: "เริ่มต้น",
                end: "สิ้นสุด",
                search: "ค้นหา",
                waiting: "รออนุมัติ",
                approve: "อนุมัติ",
                notapproved: "ไม่อนุมัติ",
                time: "เวลา",
                oldamount: "ยอดเก่า",
                balance: "คงเหลือ",
                inprogress: "กำลังดำเนินการ",
                waitingforadminapproval: "ส่งคำขอเรียบร้อย รอแอดมินอนุมัติ",
                insufficientwithdrawalamount: "จำนวนยอดที่ถอนไม่เพียงพอ",
                withdrawalspendingapproval: "มีรายการรออนุมัติถอนอยู่",
                confirm_withdrawal: "ยืนยันการถอน",
                cancel: "ยกเลิก",
                amount_to_withdraw: "จำนวนเงินที่ต้องการถอน",
                minimum: "ขั้นต่ำ",
                withdrawal_history: "ประวัติรายการถอน",
                deposit_history: "ประวัติรายการฝาก",
                enter_the_correct_amount: "ป้อนจำนวนเงินให้ถูกต้อง",
                nobankaccount: "ไม่มีบัญชีธนาคาร",
                linkyourbankaccount: "โปรดเชื่อมบัญชีธนาคารเพื่อทำการถอน",
                gotobank: "ไปยังหน้าบัญชีธนาคาร",
                nodata: "ไม่มีข้อมูล",
                no_list: "ลำดับ",
                stocks: "หุ้น",
                order_type: "ซื้อ/ขาย",
                opening_time: "เวลาเปิดออเดอร์",
                opening_price: "เปิด-ราคา",
                closing_time: "เวลาปิดออเดอร์",
                closing_price: "ปิด-ราคา",
                waiting_trade: "รอ",
                result: "ผลลัพธ์",
                tradingnow: "รายการกำลังเทรดอยู่ตอนนี้",
                coin_list: "รายการ",
                close: "ปิด",
                buy_alert: "ซื้อ",
                fillinyourbank: "กรอกบัญชีธนาคารให้ถูกต้อง",
                note: "หมายเหตุ",
                selectedcoins: "เลือก",
                register: "สมัครสมาชิก",
                pleaseenterpasswordandcharacter: "กรุณากรอกรหัสผ่านและมีอย่างน้อย 8 ตัวอักษร",
                passwordnotmatch: "รหัสผ่านไม่ตรงกัน",
                pleaseloginagain: "กรุณาเข้าสู่ระบบใหม่",
                successfullyregisterpleaselogin: "สมัครสมาชิกเรียบร้อย โปรดเข้าสู่ระบบ",
                footertxt1: "เว็บเราก่อตั้งเมื่อเดือนกุมภาพันธ์ พ.ศ. 2561 และเป็น Exchange Platform รุ่นใหม่สำหรับการซื้อขายสินทรัพย์ดิจิทัล โดยให้บริการเหนือระดับแก่บุคคลทั่วไป ให้สามารถซื้อ ขายและเก็บสินทรัพย์ดิจิทัล ได้ตามต้องการ บริษัทเราได้จดทะเบียนอย่างถูกต้องตามกฎหมายด้วยทุนจดทะเบียน 450 ล้านบาท และมีที่ตั้งสำนักงานอยู่ในกรุงเทพฯ ประเทศไทย",
                footertxt2: "คริปโทเคอร์เรนซีและโทเคนดิจิทัลมีความเสี่ยงสูง ท่านอาจสูญเสียเงินลงทุนได้ทั้งจํานวน โปรดศึกษาและลงทุนให้เหมาะสมกับระดับความเสี่ยงที่ยอมรับได้",
                warning: "คำเตือน",
                profitability: "การทำกำไร",
                ontherise: "ที่เพิ่มขึ้น",
                letstarttradingonweb: "เริ่มการเทรดบนเว็บไซต์ของเรา",
                starttradingnow: "เริ่มต้นการเทรดทันที",
                thelastestfromourbroker: "ข่าวสารล่าสุดจากโบรกเกอร์",
                newfeatures_lastestwebsiteandmore: "ฟีเจอร์ใหม่ เว็บไซต์ใหม่ล่าสุด และอื่นๆ อีกมากมาย...",
                secure_account: "บัญชีปลอดภัย",
                now_your_fingerprint: "คุณสามารถใช้ลายนิ้วมือเพื่อเข้าถึงเว็บของเราได้จากทุกที่",
                market_analysis: "วิเคราะห์ตลาด",
                understanding_support: "ทำความเข้าใจระดับแนวรับและแนวต้าน",
                risk_management: "การบริหารความเสี่ยง",
                masteringthetworule: "เรียนรู้กฎ 2% ในการซื้อขาย",
                education: "เรียนรู้",
                howtostarttradingonplatform: "วิธีการเริ่มต้นการซื้อขายบนแพลตฟอร์ม",
                anniversary: "ครบรอบปี",
                ourbrokerrevolutionizing: "โบรกเกอร์ของเราได้พลิกโฉมวงการมาตลอด 5 ปีที่ผ่านมา!",
                smoothexperienceonanydevice: "ประสบการณ์ราบรื่นบนทุกอุปกรณ์",
                whetheryouprefertrading: "ไม่ว่าคุณจะชอบเทรดที่โต๊ะทำงานหรือขณะเดินทาง -- Bitcoinsv-optionex พร้อมให้บริการคุณเสมอ",
                custombuiltplatform: "แพลตฟอร์มที่สร้างขึ้นมาเป็นพิเศษนี้สามารถปรับให้เข้ากับอุปกรณ์ทุกชนิดที่คุณเลือกใช้ได้ และการสลับอุปกรณ์นั้นทำได้อย่างราบรื่น 100%",
                teamofexpert: "ทีมงานผู้เชี่ยวชาญ",
                whyus: "ทำไมต้องเลือกเรา?",
                easytrading: "เทรดได้ง่ายๆ",
                ui_with_realtime: "อินเทอร์เฟซใช้งานง่ายพร้อมข้อมูลตลาดแบบเรียลไทม์และการเทรดด้วยคลิกเดียว เหมาะสำหรับทั้งมือใหม่และนักเทรดที่มีประสบการณ์",
                learnmore: "เรียนรู้เพิ่มเติม",
                safeandsecure: "ปลอดภัยและมั่นคง",
                advancedencryption: "การเข้ารหัสขั้นสูงและการยืนยันตัวตนสองขั้นตอนช่วยปกป้องเงินทุนและข้อมูลของคุณ เราปฏิบัติตามมาตรฐานสากลอย่างเคร่งครัด",
                customizable: "ปรับแต่งได้",
                personalize_your_trading_environment: "ปรับแต่งการเทรดของคุณด้วยกราฟที่ออกแบบเอง, อินดิเคเตอร์, และการแจ้งเตือน ปรับแพลตฟอร์มให้เข้ากับสไตล์การเทรดของคุณเอง",
                enterthenewear: "เข้าสู่ยุคสมัยใหม่",
                ofinvesting: "ของการลงทุน",
                depositviaadmin: "ฝากผ่านแอดมิน",
                aboutus: "เกี่ยวกับเรา",
                cardtype: "ประเภทบัตร",
                idcard: "บัตรประชาชน",
                passport: "พาสปอร์ต",
                ssncard: "บัตร SSN",
                driverlicense: "บัตรใบขับขี่",
                forex_open_date: "ตลาดสกุลเงิน (Forex) จะเปิดทำการตั้งแต่วันจันทร์ ตี 4:00 น. ถึง วันเสาร์ ตี 4:00 น.",
                profit: "กำไร",

                gamecamp: "ค่ายเกม",
                comingsoon: "เร็ว ๆ นี้",
                not_enough_credit: "เครดิตไม่พอ",
                timeup: "หมดเวลา",
                use: "ใช้",
                credit: "เครดิต",
                recommendedgame: "เกมแนะนำ",
                data_transmission_error: "เกิดข้อผิดพลาด",
                fillcompletephonenumber: "กรอกข้อมูลให้ครบถ้วน",
                login: "เข้าสู่ระบบ",
                phoneorpasswordincorrect: "เบอร์โทร/Usernameหรือรหัสผ่านไม่ถูกต้อง",
                phone: "เบอร์โทร",
                password: "รหัสผ่าน",
                chatwithus: "คุยกับเรา",
                enter_to_play: "เข้าเล่น",
                logout: "ออกจากระบบ",
                language_chat: "th",
                back: "ย้อนกลับ",
                game: "เกม",
                bonustime: "Bonus Time"

            }
        }, mm: {
            translation: {
                gamecamp: "ဂိမ်းတိုက်",
                comingsoon: "မကြာမီလာမည်",
                not_enough_credit: "ခရက်ဒစ် မလုံလောက်ပါ။",
                timeup: "အချိန်ြပည့်",
                use: "အသုံးပြု",
                credit: "credit",
                recommendedgame: "အကြံပြုသောဂိမ်း",
                data_transmission_error: "An error occurred",
                fillcompletephonenumber: "အချက်အလက်အားလုံးကို ဖြည့်ပေးပါ။",
                login: "လော့ဂ်အင်",
                phoneorpasswordincorrect: "ယူဇာ သို့မဟုတ် စကားဝှက် မမှန်ပါ။",
                phone: "ဖုန်းနံပါတ်",
                password: "password",
                chatwithus: "ဆက်သွယ်ရန်",
                enter_to_play: "ကစားရန်",
                logout: "ထွက်ရန်",
                language_chat: "mm",
                back: "Back",
                game: "ဂိမ်း",
                bonustime: "Bonus Time"
            }
        }
    }
})
export default i18nUser;