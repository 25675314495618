import React, { createContext, useContext, useEffect, useRef,useState } from "react";
import config from "../../config/configapi";

const WebSocketUser = createContext(null);

export const WebSocketUserProvider = ({ children }) => {
  const ws = useRef(null);
  const [websocket, setWebSocket] = useState(null);


  const sendMessage = (message) => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.send(JSON.stringify(message));
    } else {
      console.log("WebSocket is not open. Unable to send message.");
    }
  };

  useEffect(() => {
    ws.current = new WebSocket(config.API_WEBSOCKET); // Replace with your WebSocket URL

    ws.current.onopen = () => {
      console.log("WebSocket Connected");
      setWebSocket(ws.current);
    };
    // ws.current.onmessage = (message) => {
    //   const data = JSON.parse(message.data);
    // };

    ws.current.onclose = () => {
      console.log("WebSocket Disconnected");
    };

    ws.current.onerror = (error) => {
      console.log("WebSocket Error:", error);
    };

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, []);

  return (
    <WebSocketUser.Provider value={{ websocket, sendMessage }}>
      {children}
    </WebSocketUser.Provider>
  );
};

export const useWebSocket = () => {
  return useContext(WebSocketUser);
};
