import React, { useEffect, useState } from "react";
import config from "../../../config/configapi";
import imageEmpty from "../../../image/emptyimg.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import API_URL from "../../../config/api";
import LoadingScreen from "../../../Toast/LoadingScreen";
import { useTranslation } from "react-i18next";
import { Carousel } from "react-responsive-carousel";
import OpenNotification from "../../../Toast/OpenNotification";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import ImageSlideshow from "./ImageSlideshow";
import Toast2 from "../../../Toast/Toast2";
import coming_soon_img from "../../../image/coming_soon.webp";
import { getClientIP } from "../../../Function/getClientIP";
import { TOKEN_KEY } from "../../../config/token_key";

dayjs.extend(isBetween);
function Camp() {
  const URL_HOST = `${config.API_SERVER}`;
  const { t, i18n } = useTranslation();
  const [peopleId] = useState(localStorage.getItem(`${TOKEN_KEY}Userdata`));
  const [userId] = useState(
    JSON.parse(localStorage.getItem(`${TOKEN_KEY}Id`))
  );
  const [allCamp, setAllCamp] = useState([]);
  const [allHeader, setAllHeader] = useState([]);
  const [allGuide, setAllGuide] = useState([]);

  const [loading, setLoading] = useState(true);
  const [loadingheader, setLoadingheader] = useState(true);
  const [loadingGuide, setLoadingGuide] = useState(true);

  useEffect(() => {
    get_AllCamp();
    get_AllBannerHeader();
    get_AllGuide();
  }, []);

  const get_AllCamp = async () => {
    setLoading(true);
    await API_URL.get(`api/camp/allCamp`)
      .then((res) => {
        const getallcamp = res.data;
        if (getallcamp.length !== 0) {
          setAllCamp(getallcamp);
        }
      })
      .catch((err) => {
        OpenNotification({
          message: `${t("data_transmission_error")}`,
          type: 4,
        });
      });
    setLoading(false);
  };

  const get_AllBannerHeader = async () => {
    setLoadingheader(true);
    await API_URL.get(
      `api/banner/allBanner/1/${
        i18n.language === "th" ? "TH" : i18n.language === "en" ? "EN" : "MM"
      }`
    )
      .then((res) => {
        const getallheader = res.data;
        if (getallheader.length !== 0) {
          setAllHeader(getallheader);
        }
      })
      .catch((err) => {
        OpenNotification({ message: err, type: 4 });
      });
    setLoadingheader(false);
  };

  const get_AllGuide = async () => {
    setLoadingGuide(true);
    await API_URL.get(
      `api/banner/allBanner/2/${
        i18n.language === "th" ? "TH" : i18n.language === "en" ? "EN" : "MM"
      }`
    )
      .then((res) => {
        const getallheader = res.data;
        if (getallheader.length !== 0) {
          setAllGuide(getallheader);
        }
      })
      .catch((err) => {
        OpenNotification({ message: err, type: 4 });
      });
    setLoadingGuide(false);
  };
  const checkAccess = async (camp) => {
    await API_URL.post(`api/timerestriction/checkTime`, {
      campId: camp.id,
      peopleId: peopleId,
      campname: camp.campname,
    })
      .then(async (res) => {
        if (res.data.access && res.data.useCredit) {
          await Toast2.fire({
            icon: "success",
            title: `${t("use")} 1 ${t("credit")}`,
          });
          window.location.href = `/games/${camp.id}`;
        } else {
          window.location.href = `/games/${camp.id}`;
        }
      })
      .catch(async (err) => {
        err.response.data.status === 401
          ? await Toast2.fire({
              icon: "error",
              title: `${t("not_enough_credit")}`,
            })
          : err.response.data.status === 402
          ? await Toast2.fire({
              icon: "warning",
              title: `${camp.campname} ${t("timeup")}`,
            })
          : OpenNotification({
              message: `${t("data_transmission_error")}`,
              type: 4,
            });
      });
  };

  const EnterCamp = async (camp) => {

      const clientIP = await getClientIP();

      await API_URL.post(`api/history/addHistory`, {
        peopleId: userId,
        ipaddress: clientIP,
        note: `Join ( ${camp.campname} ) `,
      })
        .then(async (res) => {
          window.location.href = `/games/${camp.id}`;
        })
        .catch(async (err) => {

          OpenNotification({
            message: `${t("data_transmission_error")}`,
            type: 4,
          });
        });

    // await API_URL.post(`api/timerestriction/enterCamp`, {
    //   peopleId: peopleId,
    //   campname: camp.campname,
    // })
    //   .then(async (res) => {
    //     if (res.data.access && res.data.useCredit) {
    //       await Toast2.fire({
    //         icon: "success",
    //         title: `${t("use")} 1 ${t("credit")}`,
    //       });
    //       window.location.href = `/games/${camp.id}`;
    //     }
    //   })
    //   .catch(async (err) => {
    //     err.response.data.status === 401
    //       ? await Toast2.fire({
    //           icon: "error",
    //           title: `${t("not_enough_credit")}`,
    //         })
    //       : OpenNotification({
    //           message: `${t("data_transmission_error")}`,
    //           type: 4,
    //         });
    //   });
  };
  return (
    <div>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div>
          {loadingheader ? (
            <></>
          ) : (
            <Carousel
              autoPlay
              infiniteLoop={true}
              showArrows={true}
              showIndicators={false}
              stopOnHover={false}
              showThumbs={false}
              interval={4000}
            >
              {allHeader.map((header) => (
                <div key={header.id}>
                  <img
                    className="w-full h-full object-contain rounded-md pointer-events-none"
                    src={
                      `${URL_HOST}${header.imagebanner}` !== null
                        ? `${URL_HOST}${header.imagebanner}`
                        : header.imagebanner !== null
                        ? `${URL_HOST}${header.imagebanner}`
                        : imageEmpty
                    }
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = imageEmpty;
                    }}
                    draggable="false"
                  />
                </div>
              ))}
            </Carousel>
          )}
          <div className="px-2 py-1">
            <p className="text-base text-white mb-2">{t("recommendedgame")}</p>
            <div className="h-0.5 bg-slate-400"></div>
          </div>
          {loadingGuide ? (
            <></>
          ) : (
            <div className="w-full max-w-full">
              <ImageSlideshow allGuide={allGuide} URL_HOST={URL_HOST} />
            </div>
          )}

          <div className="px-2 py-1">
            <p className="text-base text-white mb-2">{t("gamecamp")}</p>
            <div className="h-0.5 bg-slate-400"></div>
          </div>

          <div className="grid grid-cols-2 gap-2 lg:grid-cols-6 lg:gap-4 py-1">
            {allCamp.map((camp) => (
              <button
                className={camp.status === 0 ? `pointer-events-none` : ``}
                key={camp.id}
                onClick={() => EnterCamp(camp)}
                // to={camp.status === 1 ? `/games/${camp.id}` : `#`}
              >
                <div className="bg-white p-1 lg:p-2 rounded-lg shadow relative h-full flex flex-col camp_card">
                  <div className="flex-grow flex items-center justify-center overflow-hidden rounded-md relative">
                    <LazyLoadImage
                      className="w-full h-full rounded-md pointer-events-none"
                      style={{ borderRadius: "12px" }}
                      src={
                        `${URL_HOST}${camp.imagecamp}` !== null
                          ? `${URL_HOST}${camp.imagecamp}`
                          : camp.imagecamp !== null
                          ? `${URL_HOST}${camp.imagecamp}`
                          : imageEmpty
                      }
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = imageEmpty;
                      }}
                      draggable="false"
                      alt={camp.campname}
                    />
                    {camp.status === 1 ? (
                      <></>
                    ) : (
                      <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                        {/* <p className="text-white text-lg font-bold">
                          {t("comingsoon")}
                        </p> */}
                        <LazyLoadImage src={coming_soon_img} className="h-16" />
                      </div>
                    )}
                  </div>
                  <div className="my-1">
                    <p
                      className={
                        (camp.status === 1 ? "text-white" : "text-gray-500") +
                        " text-center text-xs md:text-base font-semibold "
                      }
                    >
                      {camp.campname}
                    </p>
                  </div>
                </div>
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Camp;
