import React,{ useState, useEffect } from 'react'
import OpenNotification from '../../../Toast/OpenNotification';
import { Table, Tag,Spin } from "antd";
import { useTranslation } from 'react-i18next';
import { TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import API_URL from "../../../config/api";

function History() {
  const {t} = useTranslation();
  const [allHistory, setAllHistory] = useState([]);
  const [allHistoryBackup, setAllHistoryBackup] = useState([]);
  const [loading, setLoading] = useState(true);
  const [inputSearch, setInputSearch] = useState("");

  const [timestart, setTimestart] = useState(
    dayjs().startOf("month").format("YYYY-MM-DD")
  );
  const [timeend, setTimeend] = useState(
    dayjs().endOf("month").format("YYYY-MM-DD")
  );
  useEffect(() => {
    get_AllHistory();
  }, []);

  const get_AllHistory = async () => {
    setAllHistory([]);
    setAllHistoryBackup([]);
    setLoading(true);
    await API_URL.get(`api/history/allHistory`)
      .then((res) => {
        const getallHistory = res.data;
        if (getallHistory.length !== 0) {

          setAllHistory(getallHistory);
          setAllHistoryBackup(getallHistory);
        }
      })
      .catch((err) => {
        OpenNotification({ message: `${t("an_error_occurred")}`, type: 4 });
      });
    setLoading(false);
  };

  const timeFormat = (time) => {
    return dayjs(time).format("YYYY-MM-DD");
  };

  const changDatestart = (e) => {
    if (e.target.value >= timeend) {
      setTimestart(dayjs(timeend).add(-1, "day").format("YYYY-MM-DD"));
    } else {
      setTimestart(e.target.value);
    }
  };
  const changDateend = (e) => {
    if (e.target.value <= timestart) {
      setTimeend(dayjs(timestart).add(1, "day").format("YYYY-MM-DD"));
    } else {
      setTimeend(e.target.value);
    }
  };

  const handleChageByDate = () => {
    let data = [];

    allHistoryBackup.map((all) => {
      if (inputSearch.length === 0) {
        if (
          timeFormat(all.createdAt) >= timestart &&
          timeFormat(all.createdAt) <= timeend
        ) {
          data.push(all);
 
        }
      } else {
        if (
          timeFormat(all.createdAt) >= timestart &&
          timeFormat(all.createdAt) <= timeend &&
          all.people.uid === inputSearch
        ) {
          data.push(all);
        }
      }
    });
    OpenNotification({
      message:`${t("search_completed")}`,
      type: 1,
    });

    setAllHistory(data);
  };

  const columns_alluser = [

    {
      title: "username",
      render: (allHistory) => (
        <p className="text-xs text-black my-auto px-2">
          {allHistory.people.username}
        </p>
      ),
    },
    {
      title: `${t("phone")}`,
      render: (allHistory) => (
        <p className="text-xs text-black my-auto px-2">
          {allHistory.people.phone}
        </p>
      ),
    },

    {
      title: "IP Address",
      align: "center",
      render: (allHistory) => (
        <p className="text-xs text-black my-auto px-2">
          {allHistory.ipaddress}
        </p>
      ),
    },

    {
      title: `${t("note")}`,

      ellipsis: {
        showTitle: true,
      },
      render: (allHistory) => (
        <p className="text-xs text-black my-auto px-2">
          {allHistory.note === "" ? "-" : allHistory.note}
        </p>
      ),
    },

    {
      title: `${t("time")}`,
      width: "10%",
      align: "center",
      render: (allHistory) => (
        <p className="text-xs text-black my-auto px-2">
          {dayjs(allHistory.createdAt).format("DD/MM/YYYY HH:mm")}
        </p>
      ),
    },
  ];
  return (
    <div className="my-3">
    <div className="w-full md:flex justify-start my-1">
      <div className="w-full md:w-1/3   md:flex">
        <TextField
          className="bg-white rounded-md"
          size="small"
          color="secondary"
          id="outlined-textarea"
          autoComplete="off"
          label={`Username,${t("phone")},IP Address`}
          InputProps={{
            endAdornment: <SearchIcon position="end"></SearchIcon>,
          }}
          fullWidth
          value={inputSearch}
          onChange={(e) => {
            const currValue = e.target.value;
            setInputSearch(currValue);
            const filteredData = allHistoryBackup.filter((entry) =>
              entry.people.username
                .toLowerCase()
                .includes(currValue.toLowerCase())||
              entry.ipaddress
                .toLowerCase()
                .includes(currValue.toLowerCase())||
              entry.people.phone
                .toLowerCase()
                .includes(currValue.toLowerCase())
            );
            setAllHistory(filteredData);
          }}
        />
      </div>
      <div className="w-full lg:w-2/3 flex flex-wrap my-4 mx-0 md:mx-3 md:my-0">
        <div className="w-1/2 md:w-1/5 px-1 bg-white rounded-md">
          <TextField
            id="date"
            label={t("start")}
            type="date"
            size="small"
            value={timestart}
            onChange={(e) => changDatestart(e)}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        </div>
        <div className="w-1/2 md:w-1/5 px-1 bg-white rounded-md">
          <TextField
            id="date"
            label={t("end")}
            type="date"
            size="small"
            fullWidth
            value={timeend}
            onChange={(e) => changDateend(e)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <button
          onClick={handleChageByDate}
          className="w-full lg:w-1/12 my-1 md:my-0 px-4 py-2 bg-green-600 hover:bg-green-800 rounded-md shadow-lg text-white wsn"
        >
          {t("search")}
        </button>
      </div>
    </div>
    <Table
      className="table-striped-rows"
      size="middle"
      scroll={{
        x: 900,
      }}
      pagination={{ pageSize: 20 }}
      rowKey="id"
      columns={columns_alluser}
      dataSource={allHistory}
      loading={{
        indicator: (
          <div>
            <Spin size="large" />
          </div>
        ),
        spinning: loading,
      }}
    />

  </div>
  )
}

export default History