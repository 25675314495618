import React, { useState, useEffect } from "react";

import {
  TextField,

} from "@mui/material";


import API_URL_form from "../../.././config/apiForm";

import { Modal } from "antd";

import OpenNotification from "../../../Toast/OpenNotification";
import ErrorMessage from "../../../Toast/ErrorMessage";

import PasswordIcon from "@mui/icons-material/Password";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";

import { useWebSocket } from "../WebSocketContext";
import { useTranslation } from "react-i18next";

function Adduser({ isModalUser, showModalUser, get_Oneuser, findone, getId }) {
  const {t} = useTranslation();
  const { sendMessage } = useWebSocket();

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [username, setUsername] = useState("188bet");
  const [password, setPassword] = useState("Aa188188");

  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [idbank, setIdbank] = useState(0);
  const [codebank, setCodebank] = useState("");
  const [verify_status, setVertify_status] = useState(0);
  // const [firstnamebank, setFirstnamebank] = useState("");
  // const [lastnamebank, setLastnamebank] = useState("");

  const [addressnow, setAddressnow] = useState("");

  const [msgErr1, setMsgErr1] = useState("");
  const [msgErr2, setMsgErr2] = useState("");

  const [msgErr4, setMsgErr4] = useState("");

  useEffect(() => {
    clearForm();
    if (getId != null) {
      setForm(findone);
    }
  }, []);

  const setForm = (findone) => {
    setFirstname(findone.firstname);
    setLastname(findone.lastname);
    setUsername(findone.username);
    setPassword(findone.password);
    setPhone(findone.phone);
  };

  const clearForm = () => {
    setFirstname("");
    setLastname("");
    setUsername("188bet");
    setPassword("Aa188188");
    setPhone("");
    setEmail("");
  };

  const CreateUser = async (e) => {
    if (phone.length !== 10) {
      setMsgErr1(`${t("please_fill_phone")}`);

      return;
    }
    setMsgErr1("");

    if (password === "" || password.length < 8) {
      setMsgErr2(`${t("please_enter_password_8_character")}`);
      return;
    }
    setMsgErr2("");

    // if (codebank < 10 ) {
    //   setMsgErr4("กรุณากรอกบัญชีธนาคารให้ครบถ้วน");
    //   setValue("2");
    //   return;
    // }

    // setMsgErr4("");

    const formData = new FormData();

    formData.append("firstname", firstname.length === 0 ? "" : firstname);
    formData.append("lastname", lastname.length === 0 ? "" : lastname);
    formData.append("password", password);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("username", username);

    e.preventDefault();

    await API_URL_form.post(`api/people/newPeople`, formData)
      .then((res) => {
        OpenNotification({ message: `${t("user_added_successfully")}`, type: 1 });
        try {
          sendMessage({ type: "refreshUsers" });
        } catch (error) {}
        showModalUser();
        clearForm();
      })
      .catch((err) => {
        err.response.data.status === 400
          ? OpenNotification({ message: `${t("an_error_or_number_username_already_use")}`, type: 3 })
          : OpenNotification({ message: `${t("an_error_occurred")}`, type: 4 });
      });
  };

  const UpdateUser = async (e) => {
    e.preventDefault();
    if (phone.length !== 10) {
      setMsgErr1(`${t("please_fill_phone")}`);
      return;
    }
    setMsgErr1("");

    if (password === "" || password.length < 8) {
      setMsgErr2(`${t("please_enter_password_8_character")}`);
      return;
    }
    setMsgErr2("");

    // if (codebank < 10 ) {
    //   setMsgErr4("กรุณากรอกบัญชีธนาคารให้ครบถ้วน");
    //   setValue("2");
    //   return;
    // }

    // setMsgErr4("");

    const formData = new FormData();

    formData.append("firstname", firstname.length === 0 ? "" : firstname);
    formData.append("lastname", lastname.length === 0 ? "" : lastname);
    formData.append("password", password);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("idbank", idbank === -1 ? 0 : idbank);
    formData.append("codebank", codebank);
    formData.append("addressnow", addressnow);
    formData.append("verify_status", verify_status);

    // formData.append("firstnamebank", firstnamebank);
    // formData.append("lastnamebank", lastnamebank);

    await API_URL_form.put(`api/people/updatePeople/${getId}`, formData)
      .then((res) => {
        OpenNotification({ message:  `${t("user_been_success_edited")}`, type: 1 });
        showModalUser();
        try {
          sendMessage({ type: "refreshUsers" });
        } catch (error) {}
        get_Oneuser();
        clearForm();
        return res.data;
      })
      .catch((err) => {
        OpenNotification({
          message: `${t("an_error_or_number_username_already_use")}`,
          type: 3,
        });
      });
  };

  return (
    <div>
      <Modal
        style={{
          top: 10,
        }}
        title={getId === null ? `${t("add_user")}` : `${t("edit_user")}`}
        open={isModalUser}
        onOk={showModalUser}
        onCancel={showModalUser}
        width={900}
        footer={[
          <div className=" text-center flex justify-center">
            {getId === null ? (
              <button
                onClick={CreateUser}
                className="text-center text-white font-semibold  px-10 py-2 rounded-md bg-blue-900 hover:bg-blue-700 shadow-md"
              >
                {t("save")}
              </button>
            ) : (
              <button
                onClick={UpdateUser}
                className="text-center text-white font-semibold  px-10 py-2 rounded-md bg-orange-500 hover:bg-orange-800 shadow-md"
              >
                {t("edit")}
              </button>
            )}

            <div className="mx-5 md:mx-16 "></div>
            <button
              onClick={showModalUser}
              className="bg-transparent hover:bg-gray-100 text-black font-semibold  py-2 px-10 border border-gray-900 hover:border-transparent rounded"
            >
              {t("cancel")}
            </button>
          </div>,
        ]}
      >
        <form
          className="w-6/6 md:w-4/6 mx-auto mt-5 "
          encType="multipart/form-data"
          method="post"
        >
          <div className="flex w-full md:w-6/6 mx-auto my-5">
            <TextField
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              size="small"
              id="outlined-textarea"
              label="username"
              autoComplete="off"
              placeholder="Username"
              fullWidth
              
            />
          </div>

          <div className="flex w-full md:w-6/6 mx-auto my-5">
            <TextField
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              size="small"
              type={"tel"}
              id="outlined-textarea"
              label={t("phone")}
              onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
              autoComplete="off"
              placeholder={t("phone")}
              className="w-6/6"
              inputProps={{ maxLength: 10 }}
              fullWidth
              InputProps={{
                startAdornment: (
                  <PhoneAndroidIcon
                    position="start"
                    className="mr-5"
                  ></PhoneAndroidIcon>
                ),
              }}
            />
          </div>
          {msgErr1 ? <ErrorMessage message={msgErr1} /> : <></>}

          <div className="flex w-full md:w-6/6 mx-auto my-5">
            <TextField
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              id="outlined-textarea"
              size="small"
              label="Password"
              autoComplete="off"
              placeholder={t("password")}
              className="w-6/6 "
              fullWidth
              InputProps={{
                startAdornment: (
                  <PasswordIcon
                    position="start"
                    className="mr-5"
                  ></PasswordIcon>
                ),
              }}
            />
          </div>

          {msgErr2 ? <ErrorMessage message={msgErr2} /> : <></>}

          {msgErr4 ? <ErrorMessage message={msgErr4} /> : <></>}
        </form>
      </Modal>
    </div>
  );
}

export default Adduser;
