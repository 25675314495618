import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './icon/banks.css'

import reportWebVitals from './reportWebVitals';
import frFR from 'antd/es/locale/th_TH';
import en_US from 'antd/es/locale/en_US';
import { ConfigProvider } from 'antd';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie';

import App from './App';
import Back_main from './components/back/Back_main';
import Front_main from './components/front/Front_main'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <CookiesProvider>
    <ConfigProvider locale={en_US} theme={{
      token: {
        fontFamily: 'Sarabun',
      },
    }}>
      <BrowserRouter>
        <Routes>

          {/* <Route path="/" element={<App />} ></Route> */}
          <Route path="/*" element={<Front_main />} >
            
          </Route>

          <Route path="/admin/*" element={<Back_main />} ></Route>


        </Routes>
      </BrowserRouter>
    </ConfigProvider>

  </CookiesProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
