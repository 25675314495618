import React, { useContext, useMemo, useState, useEffect } from "react";
import { HolderOutlined } from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CreateIcon from "@mui/icons-material/Create";
import SearchIcon from "@mui/icons-material/Search";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { IconButton } from "@mui/material";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button, Table, Spin } from "antd";
import AddCampModal from "./AddCampModal";
import API_URL from "../../../config/api";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import OpenNotification from "../../../Toast/OpenNotification";
import config from "../../../config/configapi";
import imageEmpty from "../../../image/emptyimg.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
const RowContext = React.createContext({});

const DragHandle = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined />}
      style={{
        cursor: "move",
      }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};

const Camp = () => {
  const {t} = useTranslation();
  const URL_HOST = `${config.API_SERVER}`;
  const [allCamp, setAllCamp] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState(allCamp);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [getId, setGetId] = useState(null);

  useEffect(() => {
    get_AllCamp();
  }, []);
  const get_AllCamp = async () => {
    setLoading(true);
    await API_URL.get(`api/camp/allCamp`)
      .then((res) => {
        const getallcamp = res.data;
        if (getallcamp.length !== 0) {
          setAllCamp(getallcamp);
          setDataSource(getallcamp);
        }
      })
      .catch((err) => {
        OpenNotification({ message: `${t("an_error_occurred")}`, type: 4 });
      });
    setLoading(false);
  };
  const columns = [
    {
      key: "sort",
      align: "center",
      width: 80,
      render: () => <DragHandle />,
    },
    {
      title: `${t("no_number")}`,
      dataIndex: "order",
      width: "5%",
      render: (order) => <p className="text-xs  my-auto truncate"> {order}</p>,
    },
    {
      title: `${t("icon")}`,
      dataIndex: "iconcamp",
      render: (iconcamp) => (
        <LazyLoadImage
          className="h-16 object-contain m-0 rounded-md"
          src={
            `${URL_HOST}${iconcamp}` !== null
              ? `${URL_HOST}${iconcamp}`
              : null && iconcamp !== null
              ? `${URL_HOST}${iconcamp}`
              : imageEmpty
          }
          onError={({ currentTarget }) => {
            currentTarget.onerror =
              `${URL_HOST}${iconcamp}` !== null
                ? `${URL_HOST}${iconcamp}`
                : null; // prevents looping
            currentTarget.src = imageEmpty;
          }}
        />
      ),
    },
    {
      title: `${t("image")}`,
      dataIndex: "imagecamp",
      render: (imagecamp) => (
        <LazyLoadImage
          className="h-20 object-contain m-0 rounded-md"
          src={
            `${URL_HOST}${imagecamp}` !== null
              ? `${URL_HOST}${imagecamp}`
              : null && imagecamp !== null
              ? `${URL_HOST}${imagecamp}`
              : imageEmpty
          }
          onError={({ currentTarget }) => {
            currentTarget.onerror =
              `${URL_HOST}${imagecamp}` !== null
                ? `${URL_HOST}${imagecamp}`
                : null; // prevents looping
            currentTarget.src = imageEmpty;
          }}
        />
      ),
    },
    {
      title:  `${t("game_camp_name")}`,
      dataIndex: "campname",
      render: (campname) => (
        <p className="text-sm font-semibold  my-auto truncate"> {campname}</p>
      ),
    },
    {
      title: `${t("status")}`,
      dataIndex: "status",
      render: (status) => (
        <>
          {status === 1 ? (
            <CheckCircleIcon className="text-green-600 ml-0.5 scale-75" />
          ) : (
            <CancelIcon className="text-red-600 ml-0.5 scale-75" />
          )}
        </>
      ),
    },
    {
      title: `${t("total_games")}`,
      dataIndex: "id",
      width: "15%",
      render: (id, allCamp) => (
        <div className="flex justify-between gap-3">
          <p className="text-xs  my-auto truncate "> {allCamp.games.length}</p>
          <Link
            className="my-auto flex align-middle bg-green-100 hover:bg-green-200 rounded-md px-2 py-1"
            to={`/admin/camp/${id}`}
          >
            <SearchIcon fontSize="medium" color="success" />
            <p className="font-medium text-black ml-2 whitespace-nowrap">
            {t("view_all")}
            </p>
          </Link>
        </div>
      ),
    },
    {
      title: `${t("management")}`,
      dataIndex: "id",
      width: "15%",
      render: (id, allCamp) => (
        <div className="flex">
          <button
            onClick={() => showModalCampEdit(id)}
            className="my-auto flex align-middle bg-slate-100 hover:bg-slate-200 rounded-md px-2 py-0.5"
          >
            <CreateIcon fontSize="medium" color="warning" />
            <p>{t("edit")}</p>
          </button>
          <IconButton onClick={() => showDeleteConfirm(id, allCamp)}>
            <DeleteForeverIcon fontSize="small" color="error" />
          </IconButton>
        </div>
      ),
    },
  ];

  const showDeleteConfirm = (id, camp) => {
    Swal.fire({
      title: `${t("confirm_delete_this_camp")}`,
      text: camp.campname,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `${t("confirm_deletion")}`,
      cancelButtonText: `${t("cancel")}`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await API_URL.delete(`api/camp/deleteCamp/${id}`)
          .then(async () => {
            try {
              get_AllCamp();
            } catch (error) {
              console.log("Failed:", error);
            }
            try {
              if (camp.imagecamp !== null) {
                let string = "";
                const array = camp.imagecamp.split("\\");
                string = "./" + array.join("/");

                await API_URL.post(`api/camp/deleteimagecamp`, {
                  id: null,
                  imagecampBackup: string,
                });
              }
            } catch (e) {}
            OpenNotification({ message: `${t("deleted_successfully")}`, type: 1 });
          })
          .catch((err) => {
            OpenNotification({ message: `${t("an_error_occurred")}`, type: 4 });
          });
      }
    });
  };

  const Row = (props) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props["data-row-key"],
    });
    const style = {
      ...props.style,
      transform: CSS.Translate.toString(transform),
      transition,
      ...(isDragging
        ? {
            position: "relative",
            // zIndex: 9999,
          }
        : {}),
    };
    const contextValue = useMemo(
      () => ({
        setActivatorNodeRef,
        listeners,
      }),
      [setActivatorNodeRef, listeners]
    );
    return (
      <RowContext.Provider value={contextValue}>
        <tr {...props} ref={setNodeRef} style={style} {...attributes} />
      </RowContext.Provider>
    );
  };

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource((prevState) => {
        const activeIndex = prevState.findIndex(
          (record) => record.order === active?.id
        );
        const overIndex = prevState.findIndex(
          (record) => record.order === over?.id
        );
        return arrayMove(prevState, activeIndex, overIndex);
      });

      setAllCamp(async (prevState) => {
        const activeIndex = prevState.findIndex(
          (record) => record.order === active.id
        );
        const overIndex = prevState.findIndex(
          (record) => record.order === over.id
        );
        const newOrder = arrayMove(prevState, activeIndex, overIndex);
        const data_neworder = newOrder.map((item, index) => ({
          // ...item,
          id: item.id,
          order: index + 1,
        }));
        await API_URL.put(`api/camp/updateOrder`, data_neworder)
          .then((res) => {
            get_AllCamp();
          })
          .catch((err) => {
            OpenNotification({ message: `${t("an_error_occurred")}`, type: 4 });
          });
      });
    }
  };
  const showModal = () => {
    setIsModalVisible(!isModalVisible);
    if (isModalVisible === true) {
      setGetId(null);
    }
  };
  const showModalCampEdit = (id) => {
    setGetId(id);
    setIsModalVisible(!isModalVisible);
  };

  return (
    <div>
      {isModalVisible ? (
        <AddCampModal
          isModalVisible={isModalVisible}
          showModal={showModal}
          get_AllCamp={get_AllCamp}
          allCamp={allCamp}
          getId={getId}
        />
      ) : (
        <></>
      )}
      <div className="grid grid-cols-2">
        <div className="flex justify-start">
          <button
            onClick={showModal}
            className=" justify-center text-center py-2 md:py-2 px-10 rounded-md shadow-lg bg-red-500 hover:bg-red-700 text-white text-sm md:text-md"
          >
           {t("add_game_camps")}
          </button>
        </div>
        <div></div>
      </div>
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          items={dataSource.map((i) => i.order)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            rowKey="order"
            scroll={{
              x: 800,
            }}
            components={{
              body: {
                row: Row,
              },
            }}
            columns={columns}
            dataSource={dataSource}
            loading={{
              indicator: (
                <div>
                  <Spin size="large" />
                </div>
              ),
              spinning: loading,
            }}
          />
        </SortableContext>
      </DndContext>
    </div>
  );
};
export default Camp;
