import React, { useState, useEffect } from "react";
import API_URL from "../config/api";


function Nameweb() {
  const [webname, setWebname] = useState("");

  useEffect(() => {
    getWebUrl();
  }, []);
  const getWebUrl = async () => {
    await API_URL.get(`api/weburl/getlogoandname`)
      .then((res) => {
        const allweburl = res.data;
        if (allweburl.length !== 0) {
          const reswebname = allweburl.find(({ name }) => name === "webname");
          setWebname(reswebname.nameurl);
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return <h2 className="text-xs lg:text-xl font-semibold truncate">{webname}</h2>;
}

export default Nameweb;
