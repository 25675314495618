import React, { useState } from "react";
import Login from "./Login";
import "./back_style.css";
import Dashboard from "./Dashboard";
import i18nAdmin from"./Language/i81n";
import { I18nextProvider } from 'react-i18next';
import {TOKEN_KEY} from '../../config/token_key'

export default function Back_main() {
  const [token] = useState(localStorage.getItem(TOKEN_KEY));
  return <I18nextProvider i18n={i18nAdmin}>{!token ? <Login /> : <Dashboard />}</I18nextProvider>;
}
