import React, { useState,useEffect } from "react";
import API_URL from "../config/api";
import { LazyLoadImage } from "react-lazy-load-image-component";
import imageEmpty from "../icon/188bet_512x512_backup.png";
import config from "../config/configapi";


function Logoweb() {
  const [imglogoweb, setImglogoweb] = useState(null);
  const [webname, setWebname] = useState("");
  const URL_HOST = `${config.API_SERVER}`;
  useEffect(() => {
    getWebUrl();
  }, []);
  const getWebUrl = async () => {
    await API_URL.get(`api/weburl/getlogoandname`)
      .then((res) => {
        const allweburl = res.data;
        if (allweburl.length !== 0) {
          const resimglogoweb = allweburl.find(
            ({ name }) => name === "imglogoweb"
          );
          setImglogoweb(
            resimglogoweb.nameurl !== null
              ? `${URL_HOST}${resimglogoweb.nameurl}`
              : null
          );
          const reswebname = allweburl.find(({ name }) => name === "webname");
          setWebname(reswebname.nameurl);
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <LazyLoadImage
      className="h-full object-contain m-0"
    //   style={{ height: "50px" }}
      src={
        imglogoweb !== null
          ? `${imglogoweb}`
          : null && imglogoweb !== null
          ? `${imglogoweb}`
          : imageEmpty
      }
      onError={({ currentTarget }) => {
        currentTarget.onerror = imglogoweb !== null ? `${imglogoweb}` : null; // prevents looping
        currentTarget.src = imageEmpty;
      }}
      alt={webname}
    />
  );
}

export default Logoweb;
