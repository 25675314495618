import API from "../config/api";
import { TOKEN_KEY } from "../config/token_key";

const signin = async (username, password) => {
  return await API.post("/api/auth/signin", {
    username: username,
    password: password,
  }).then(res => {
    if (res.data.accessToken) {
      localStorage.setItem(`${TOKEN_KEY}`, JSON.stringify(res.data));
    }
    return res.data;
  })
}

const logout = () => {
  localStorage.removeItem(`${TOKEN_KEY}`);
  window.location.reload();
}

const register = (name, username, password) => {
  return API.post("/api/auth/signup", {
    name,
    username,
    password,
    roles:["admin"]
  });
}

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem(`${TOKEN_KEY}`));
}

export {
  signin,
  logout,
  register, 
  getCurrentUser
}