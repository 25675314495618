import React, { useContext, useMemo, useState, useEffect } from "react";
import { OutlinedInput } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { AllGameModal } from "./AllGameModal";
import config from "../../../config/configapi";
import imageEmpty from "../../../image/emptyimg.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  HolderOutlined,
  LeftOutlined,
  AppstoreAddOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { Button, Table, Spin } from "antd";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import TextField from "@mui/material/TextField";
import API_URL from "../../../config/api";
import OpenNotification from "../../../Toast/OpenNotification";
import ErrorMessage from "../../../Toast/ErrorMessage";

const RowContext = React.createContext({});

const DragHandle = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined />}
      style={{ cursor: "move" }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};

const EditableTimeField = ({ value, onChange, onBlur, placeholder, label }) => {
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    setLocalValue(value); // Sync local value with props when it changes
  }, [value]);

  return (
    <TextField
      value={localValue}
      onChange={(e) => setLocalValue(e.target.value)}
      onBlur={() => onBlur(localValue)} // Update parent state on blur
      placeholder={placeholder}
      label={label}
      inputProps={{
        maxLength: 5,
      }}
    />
  );
};

function AddBonustime() {
  const { t } = useTranslation();
  let { presetId } = useParams();
  const navigate = useNavigate();
  const [isModalGame, setIsModalGame] = useState(false);
  const [presetName, setPresetName] = useState("");
  const [selectGame, setSelectGame] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [msgErr1, setMsgErr1] = useState("");

  const URL_HOST = `${config.API_SERVER}`;

  useEffect(() => {
    if (typeof presetId !== "undefined") {
      get_AllGame();
    }
  }, []);

  const get_AllGame = async () => {
    setLoading(true);
    await API_URL.get(`api/preset/OnePreset/${presetId}`)
      .then((res) => {
        const getallgame = res.data;
        if (getallgame.length !== 0) {
          setPresetName(getallgame.name);
          setDataSource(getallgame.preset_games);
        }
      })
      .catch((err) => {
        OpenNotification({ message: `${t("an_error_occurred")}`, type: 4 });
      });

    setLoading(false);
  };

  const handleStartTimeChange = (gameId, value) => {
    const formattedTime = formatTimeInput(value);
    setDataSource((prev) =>
      prev.map((game) =>
        game.id === gameId ? { ...game, startTime: formattedTime } : game
      )
    );
  };

  const handleEndTimeChange = (gameId, value) => {
    const formattedTime = formatTimeInput(value);
    setDataSource((prev) =>
      prev.map((game) =>
        game.id === gameId ? { ...game, endTime: formattedTime } : game
      )
    );
  };

  const formatTimeInput = (value) => {
    const numbers = value.replace(/[^\d]/g, "");

    if (numbers.length === 2) {
      return numbers + ":";
    } else if (numbers.length > 2) {
      return numbers.slice(0, 2) + ":" + numbers.slice(2);
    }
    return numbers;
  };

  const showModal = () => {
    setIsModalGame(!isModalGame);
  };

  const handleDataFromModal = (selectedGames) => {
    const updatedGames = selectedGames.map((game, index) => ({
      ...game,
      order: index + 1,
      startTime: game.startTime || "",
      endTime: game.endTime || "",
    }));

    setDataSource(updatedGames);
    setSelectGame(selectedGames);
  };

  const Row = (props) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props["data-row-key"],
    });

    const style = {
      ...props.style,
      transform: CSS.Translate.toString(transform),
      transition,
      ...(isDragging
        ? {
            position: "relative",
          }
        : {}),
    };

    const contextValue = useMemo(
      () => ({
        setActivatorNodeRef,
        listeners,
      }),
      [setActivatorNodeRef, listeners]
    );

    return (
      <RowContext.Provider value={contextValue}>
        <tr {...props} ref={setNodeRef} style={style} {...attributes} />
      </RowContext.Provider>
    );
  };

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource((prevState) => {
        const activeIndex = prevState.findIndex(
          (record) => record.order === active?.id
        );
        const overIndex = prevState.findIndex(
          (record) => record.order === over?.id
        );
        const updatedData = arrayMove(prevState, activeIndex, overIndex);

        // ปรับ order ใหม่ให้เรียงจาก 1
        return updatedData.map((item, index) => ({
          ...item,
          order: index + 1,
        }));
      });
    }
  };

  const columns = [
    {
      key: "sort",
      align: "center",
      width: 80,
      render: () => <DragHandle />,
    },
    {
      title: `${t("no_number")}`,
      dataIndex: "order",
      width: "5%",
      render: (order) => <p className="text-xs my-auto truncate">{order}</p>,
    },
    {
      title: `${t("icon")}`,
      width: "10%",
      dataIndex: "icongame",
      render: (icongame) => (
        <LazyLoadImage
          className="h-16 w-full object-contain m-0 rounded-md"
          src={
            `${URL_HOST}${icongame}` !== null
              ? `${URL_HOST}${icongame}`
              : null && icongame !== null
              ? `${URL_HOST}${icongame}`
              : imageEmpty
          }
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = imageEmpty;
          }}
        />
      ),
    },
    {
      title: `${t("image")}`,
      dataIndex: "imagegame",
      width: "15%",
      render: (imagegame) => (
        <LazyLoadImage
          className="h-16 object-contain m-0 rounded-md"
          src={
            `${URL_HOST}${imagegame}` !== null
              ? `${URL_HOST}${imagegame}`
              : null && imagegame !== null
              ? `${URL_HOST}${imagegame}`
              : imageEmpty
          }
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = imageEmpty;
          }}
        />
      ),
    },
    {
      title: `${t("game_name")}`,
      dataIndex: "gamename",
      width: "20%",
      render: (gamename) => (
        <p className="text-sm font-semibold my-auto truncate">{gamename}</p>
      ),
    },
    {
      title: `${t("game_camp_name")}`,
      width: "20%",
      render: (record) => (
        <p className="text-sm font-semibold my-auto truncate">
          {record.camp.campname}
        </p>
      ),
    },
    {
      title: `${t("management")}`,
      render: (_, record) => (
        <div className="flex">
          <div className="col-span-2">
            <EditableTimeField
              value={record.startTime || ""}
              onChange={(value) => handleStartTimeChange(record.id, value)}
              onBlur={(value) => handleStartTimeChange(record.id, value)}
              placeholder="HH:mm"
              label="Start (HH:mm)"
            />
          </div>
          <div className="col-span-1 mx-2 my-auto text-center font-bold">
            {" "}
            -{" "}
          </div>
          <div className="col-span-2">
            <EditableTimeField
              value={record.endTime || ""}
              onChange={(value) => handleEndTimeChange(record.id, value)}
              onBlur={(value) => handleEndTimeChange(record.id, value)}
              placeholder="HH:mm"
              label="End (HH:mm)"
            />
          </div>
        </div>
      ),
    },
  ];

  const save_game = async () => {
    if (presetName === "") {
      setMsgErr1(`${t("fill_in_information_completely")}`);
      return;
    }
    setMsgErr1("");
    if (dataSource.length <= 0) {
      setMsgErr1(`${t("add_least_one_game")}`);
      return;
    }
    setMsgErr1("");
    // console.log({ presetname: presetName, dataSource });

    if (typeof presetId !== "undefined") {
      await API_URL.put(`api/preset/updatePreset/${presetId}`, {
        presetname: presetName,
        dataSource,
      })
        .then((res) => {
          OpenNotification({ message: `${t("finish")}`, type: 1 });
          get_AllGame();
        })
        .catch((err) => {
          OpenNotification({ message: `${t("an_error_occurred")}`, type: 4 });
        });
    } else {
      await API_URL.post(`api/preset/addPreset`, {
        presetname: presetName,
        dataSource,
      })
        .then((res) => {
          OpenNotification({ message: `${t("finish")}`, type: 1 });
          navigate(-1);
          return res.data;
        })
        .catch((err) => {
          OpenNotification({ message: `${t("an_error_occurred")}`, type: 4 });
        });
    }
  };

  return (
    <div>
      {isModalGame && (
        <AllGameModal
          isModalGame={isModalGame}
          showModal={showModal}
          setDataSource={setDataSource}
          initialSelectedGames={dataSource}
        />
      )}
      <nav className="flex items-center justify-between h-14 bg-gray-500 rounded-md px-6 py-3 md:py-5 lg:py-2 w-full z-30 drop-shadow-xl max-h-20">
        <div className="w-2/4 ">
          <button
            onClick={() => navigate(-1)}
            className="bg-orange-500 hover:bg-orange-700 text-shadow-1  text-white  font-bold py-2 px-4 rounded-md inline-flex items-center shadow-md"
          >
            <svg
              className="drop-shadow-1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              width="24"
              height="24"
              strokeWidth="1.5"
            >
              <path d="M13.883 5.007l.058 -.005h.118l.058 .005l.06 .009l.052 .01l.108 .032l.067 .027l.132 .07l.09 .065l.081 .073l.083 .094l.054 .077l.054 .096l.017 .036l.027 .067l.032 .108l.01 .053l.01 .06l.004 .057l.002 .059v12c0 .852 -.986 1.297 -1.623 .783l-.084 -.076l-6 -6a1 1 0 0 1 -.083 -1.32l.083 -.094l6 -6l.094 -.083l.077 -.054l.096 -.054l.036 -.017l.067 -.027l.108 -.032l.053 -.01l.06 -.01z"></path>
            </svg>
            <span className="ml-2"> {t("back")}</span>
          </button>
        </div>
      </nav>

      <div className="mt-5 w-full grid grid-cols-1 md:grid-cols-3 items-end">
        <div className="my-auto col-span-1 md:col-span-2 w-full md:w-2/3">
          <div className="w-full flex items-center">
            <p className="whitespace-nowrap mr-3">{t("name")} Preset</p>
            <OutlinedInput
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              value={presetName}
              onChange={(e) => setPresetName(e.target.value)}
              className="block w-full text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
              aria-describedby="outlined-weight-helper-text"
              autoComplete="off"
              fullWidth
              size="small"
            />
            <button
              onClick={() => save_game()}
              className="ml-2 bg-green-500 hover:bg-green-700 text-shadow-1  text-white  font-bold py-2 px-6 rounded-md inline-flex items-center shadow-md"
            >
              <svg
                className="drop-shadow-1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                width="24"
                height="24"
                strokeWidth="1.5"
              >
                <path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2"></path>
                <path d="M12 14m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                <path d="M14 4l0 4l-6 0l0 -4"></path>
              </svg>
              <span className="ml-2 font-semibold"> {t("save")}</span>
            </button>
          </div>
          {msgErr1 ? <ErrorMessage message={msgErr1} /> : <></>}
        </div>
        <div className="col-span-1 flex items-end align-bottom mt-3 md:mt-0 justify-end md:justify-start">
          {/* <Button
            size={"large"}
            danger
            type="primary"
            icon={<AppstoreAddOutlined />}
            className="w-2/3"
            onClick={showModal}
          >
            {t("select_game")}
          </Button> */}
          <button
            onClick={showModal}
            className="ml-2 bg-purple-500 hover:bg-purple-700 text-shadow-1  text-white  font-bold py-2 px-20 rounded-md inline-flex items-center shadow-md border-2 border-purple-700"
          >
            <svg
            className="drop-shadow-1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              width="24"
              height="24"
              strokeWidth="1.5"
            >
              <path d="M4 4h6v6h-6z"></path>
              <path d="M14 4h6v6h-6z"></path>
              <path d="M4 14h6v6h-6z"></path>
              <path d="M17 17m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
            </svg>
            <span className="ml-2 font-semibold"> {t("select_game")}</span>
          </button>
        </div>
      </div>

      <div className="py-4">
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
          <SortableContext
            items={dataSource.map((i) => i.order)}
            strategy={verticalListSortingStrategy}
          >
            <Table
              rowKey="order"
              scroll={{
                x: 800,
              }}
              components={{
                body: {
                  row: Row,
                },
              }}
              pagination={{ pageSize: 50 }}
              columns={columns}
              dataSource={dataSource}
              loading={{
                indicator: (
                  <div>
                    <Spin size="large" />
                  </div>
                ),
                spinning: loading,
              }}
            />
          </SortableContext>
        </DndContext>
        {/* <Table
          rowKey="order"
          scroll={{
            x: 800,
          }}
          pagination={{ pageSize: 50 }}
          columns={columns}
          dataSource={dataSource}
          loading={{
            indicator: (
              <div>
                <Spin size="large" />
              </div>
            ),
            spinning: loading,
          }}
        /> */}
      </div>
    </div>
  );
}
export default AddBonustime;
