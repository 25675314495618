import React, { useState, useEffect } from "react";
import config from "../../../config/configapi";

import OpenNotification from "../../../Toast/OpenNotification";
import ErrorMessage from "../../../Toast/ErrorMessage";
import apiForm from "../../../config/apiForm";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import FilterIcon from '@mui/icons-material/Filter';
import API_URL from "../../../config/api";
import DraggableBanner from "./DraggableBanner";
import Loading from "../../../Toast/LoadingScreen";
import TH_icon from '../../../icon/th_icon.png'
import EN_icon from '../../../icon/en_icon.png'
import MM_icon from '../../../icon/mm_icon.png'
import { useTranslation } from "react-i18next";
function Banner() {
  const {t} = useTranslation();
  const [type, setType] = useState("1");
  const [language, setLanguage] = useState("TH");
  const [msgErr1, setMsgErr1] = useState("");

  const [imagebanner, setImagebanner] = useState({});
  const [imagePreviewbanner, setImagePreviewbanner] = useState(null);
  const [imagebannerBackup, setImagebannerBackup] = useState({});
  const [checkimagebanner, setCheckimagebanner] = useState(false);
  const [loading, setLoading] = useState(true);
  const [allHeader, setAllHeader] = useState([]);

  useEffect(() => {
    get_AllBannerHeader();
  }, []);

  const get_AllBannerHeader = async () => {
    setLoading(true);
    await API_URL.get(`api/banner/allBanner`)
      .then((res) => {
        const getallheader = res.data;
        if (getallheader.length !== 0) {
          setAllHeader(getallheader);
        }
      })
      .catch((err) => {
        OpenNotification({ message: err, type: 4 });
      });
    setLoading(false);
  };

  const CreateBanner = async () => {
    setLoading(true);
    if (imagePreviewbanner === null) {
      setMsgErr1(`${t("please_upload_picture")}`);
      setLoading(false);
      return;
    }
    setMsgErr1("");

    const formData = new FormData();
    formData.append("imagebanner", imagebanner);
    formData.append("type", type);
    formData.append("language", language);

    await apiForm
      .post(`api/banner/newBannerHeader`, formData)
      .then((res) => {
        OpenNotification({ message: `${t("finish")}`, type: 1 });
        setImagePreviewbanner(null);
        setImagebanner({});
        get_AllBannerHeader();
      })
      .catch((err) => {
        OpenNotification({ message: `${t("an_error_occurred")}`, type: 4 });
      });
    setLoading(false);
  };
  const handleDelImagebanner = (e) => {
    setImagebanner({});
    setImagePreviewbanner(null);
    setCheckimagebanner(true);
  };
  const handleUploadImagebanner = (e) => {
    if (e.target.files[0].size > 20315594) {
      OpenNotification({ message: `${t("image_size_must_not_exceed_20_mb")}`, type: 3 });
      return;
    }
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagebanner(file);
      setImagePreviewbanner(reader.result);
      setCheckimagebanner(true);
    };
    reader.readAsDataURL(file);
  };

  const handleOrderChange = async (newOrder) => {
    try {
      const updatedItems = newOrder.map((item, index) => ({
        id: item.id,
        order: index + 1,
      }));

      
        await API_URL.put(`api/banner/updateOrder`, updatedItems).then((res) => {
            get_AllBannerHeader();
        });
    } catch (error) {
      OpenNotification({ message: `${t("an_error_occurred")}`, type: 4 });
    }
  };

  return (
    <div>
      <div className="w-full">
        <div className="grid grid-cols-1 gap-1 lg:grid-cols-4 lg:gap-3">
          <div className="w-full col-span-3">
            <div className="flex justify-center mt-8">
              <div className="w-full rounded-lg shadow-xl bg-gray-50">
                <div className="m-4">
                  <div>
                    <label className="inline-block mb-2 text-gray-500">
                    {t("upload_image")}
                    </label>
                    <IconButton onClick={handleDelImagebanner}>
                      <DeleteForeverIcon color="error" />
                    </IconButton>
                  </div>

                  <div className="flex items-center justify-center w-full">
                    {imagePreviewbanner ? (
                      <img
                        className="rounded-sm h-48"
                        src={imagePreviewbanner && imagePreviewbanner}
                        alt="nice2"
                      />
                    ) : (
                      <label className="flex flex-col w-full h-48 border-4 border-blue-200 border-dashed hover:bg-gray-100 hover:border-gray-300">
                        <div className="flex flex-col items-center justify-center mt-20">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-8 h-8 text-gray-400 group-hover:text-gray-600"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                            />
                          </svg>
                          <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                          {t("select_image_file")}
                          </p>
                        </div>
                        <input
                          type="file"
                          accept="image/png, image/jpeg,image/webp"
                          className="opacity-0"
                          name="imagedriving"
                          onChange={handleUploadImagebanner}
                        />
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-3/4 flex flex-row flex-wrap lg:flex-col justify-center align-middle">
            <div className="flex w-1/2 mx-auto my-1">
              <FormControl className="w-full whitespace-nowrap flex">
                <InputLabel className="my-auto">{t("language")}</InputLabel>
                <Select
                  name="language"
                  size="small"
                  className="flex cus_selet"
                  inputProps={{
                    style: { display: "grid", whiteSpace: "nowrap",gridTemplateColumns:"1fr 1fr" },
                  }}
                  label={t("language")}
                  defaultValue={language}
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                  fullWidth
                >
                  <MenuItem value={"TH"} className="flex align-middle">
                  <img src={TH_icon} className="h-5 mr-1"/>TH
                  </MenuItem>
                  <MenuItem value={"EN"} className="flex align-middle">
                  <img src={EN_icon} className="h-5 mr-1"/>EN
                  </MenuItem>
                  <MenuItem value={"MM"} className="flex align-middle">
                  <img src={MM_icon} className="h-5 mr-1"/>MM
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="flex w-1/2 lg:w-full mx-auto my-1">
              <FormControl className="w-full whitespace-nowrap flex">
                <InputLabel className="my-auto">type</InputLabel>
                <Select
                  name="type"
                  size="small"
                  className="flex"
                  inputProps={{
                    style: { display: "flex", whiteSpace: "nowrap" },
                  }}
                  label="type"
                  defaultValue={type}
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  fullWidth
                >
                  <MenuItem value={1} className="flex align-middle">
                  {t("banner_image")}
                  </MenuItem>
                  <MenuItem value={2} className="flex align-middle">
                  {t("recommended_image")}
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <button
              onClick={CreateBanner}
              className="text-center text-white font-semibold  px-10 py-2 w-full rounded-md bg-green-500 hover:bg-green-800 shadow-md shadow-slate-300 "
              style={{textShadow:"0px 1px 2px black"}}
            >
              {t("upload_image")}
            </button>
            {msgErr1 ? <ErrorMessage message={msgErr1} /> : <></>}
          </div>
        </div>
        <div className="mt-3 px-4">
          <div className="border-b-2 border-gray-400 pb-1">
            <div className="flex justify-start mt-1">
              <FilterIcon className="mr-2" /> <p className="font-semibold">{t("all_photo")}</p>
            </div>
          </div>
        </div>
        <div className="mt-3">
          {loading ? (
            <Loading />
          ) : (
            <div className=" mx-auto px-2">
              <DraggableBanner
                allHeader={allHeader}
                onOrderChange={handleOrderChange}
                get_AllBannerHeader={get_AllBannerHeader}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Banner;
