import React, { useEffect, useState } from "react";
import config from "../../../config/configapi";
import imageEmpty from "../../../image/emptyimg.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import API_URL from "../../../config/api";
import LoadingScreen from "../../../Toast/LoadingScreen";
import OpenNotification from "../../../Toast/OpenNotification";
import { useTranslation } from "react-i18next";

export default function Bonustime() {
  const URL_HOST = `${config.API_SERVER}`;
  const { t } = useTranslation();
  const [allPreset, setAllPreset] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    get_AllPreset();
  }, []);

  const get_AllPreset = async () => {
    setLoading(true);
    await API_URL.get(`api/preset/OnePresetUser`)
      .then((res) => {
        const getallpreset = res.data;
        if (getallpreset.length !== 0) {
          setAllPreset(getallpreset);
        }
      })
      .catch((err) => {
        OpenNotification({
          message: `${t("data_transmission_error")}`,
          type: 4,
        });
      });
    setLoading(false);
  };

  return (
    <div>
      <div>
        <div className="px-2 pb-2">
          <p className="text-base text-white mb-2">{t("bonustime")}</p>
          <div className="h-0.5 bg-slate-400"></div>
        </div>
        {loading ? (
          <LoadingScreen />
        ) : (
          <div className="w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 md:gap-8 lg:gap-10 p-2 md:p-2">
            {allPreset.map((preset) => (
              <div className=" lg:py-6 relative">
                <div className="absolute -top-2 lg:top-1 -left-1 lg:-left-3 px-0.5 rounded-md icon-position ">
                  <LazyLoadImage
                    className="w-full h-12 md:h-16 lg:h-20 object-contain m-0 rounded-md"
                    src={
                      `${URL_HOST}${preset.iconcamp}` !== null
                        ? `${URL_HOST}${preset.iconcamp}`
                        : null && preset.iconcamp !== null
                        ? `${URL_HOST}${preset.iconcamp}`
                        : imageEmpty
                    }
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = imageEmpty;
                    }}
                    style={{
                      userSelect: "none",
                      pointerEvents: "none",
                      WebkitTouchCallout: "none",
                    }}
                    onContextMenu={(e) => e.preventDefault()} // ป้องกันการคลิกขวา
                    draggable="false"
                  />
                </div>
                <div className="absolute bottom-0 lg:bottom-5 -left-1 lg:-left-3 px-0.5 rounded-md icon-position">
                  <LazyLoadImage
                    className="w-full h-12 md:h-16 lg:h-20 object-contain m-0 rounded-md"
                    src={
                      `${URL_HOST}${preset.icongame}` !== null
                        ? `${URL_HOST}${preset.icongame}`
                        : null && preset.icongame !== null
                        ? `${URL_HOST}${preset.icongame}`
                        : imageEmpty
                    }
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = imageEmpty;
                    }}
                    style={{
                      userSelect: "none",
                      pointerEvents: "none",
                      WebkitTouchCallout: "none",
                    }}
                    onContextMenu={(e) => e.preventDefault()} // ป้องกันการคลิกขวา
                    draggable="false"
                  />
                </div>

                <div className="bg-gray-600 p-1 lg:p-2 rounded-lg lg:rounded-2xl ">
                  <div className="border-2 border-gray-500 rounded-lg">
                    <LazyLoadImage
                      className="w-full object-contain m-0 rounded-t-md"
                      src={
                        `${URL_HOST}${preset.imagegame}` !== null
                          ? `${URL_HOST}${preset.imagegame}`
                          : null && preset.imagegame !== null
                          ? `${URL_HOST}${preset.imagegame}`
                          : imageEmpty
                      }
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = imageEmpty;
                      }}
                      style={{
                        userSelect: "none",
                        pointerEvents: "none",
                        WebkitTouchCallout: "none",
                      }}
                      onContextMenu={(e) => e.preventDefault()} // ป้องกันการคลิกขวา
                      draggable="false"
                    />
                    <div className="w-full flex justify-end bg-purple-900 rounded-b-md pr-1 lg:pr-2">
                      <span className="font-bold text-white text-base md:text-lg lg:text-2xl text-right">
                        {preset.startTime}&nbsp;&nbsp;-&nbsp;&nbsp;
                        {preset.endTime}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
