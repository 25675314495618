import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import EN_img from "../icon/en_icon.png";
import TH_img from "../icon/th_icon.png";
import MM_img from "../icon/mm_icon.png";
const languages = [
  { code: "en", lang: "English", img: EN_img },
  { code: "th", lang: "ไทย", img: TH_img },
  { code: "mm", lang: "မြန်မာ", img: MM_img },
];

function LanguageSelector() {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem(`i18nextLng`, lng);
    setIsOpen(!isOpen);
  };


  return (


    <div className="relative">
      <button onClick={() => setIsOpen(!isOpen)} className="inline-flex my-auto px-2 py-1 md:px-4 md:py-2 rounded-md lg:rounded-lg items-center align-middle justify-center w-full  border border-gray-700 shadow-sm  bg-zinc-900 text-sm font-medium text-gray-700 hover:bg-zinc-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-zinc-700">
        <img
          src={i18n.language === "th" ?  TH_img:i18n.language === "en"? EN_img:MM_img}
          className="h-7 md:h-7"
        />
        <svg
          className="-me-1 ms-2 h-5 w-5"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M10.293 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L10 12.586l3.293-3.293a1 1 0 011.414 1.414l-4 4z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      {isOpen && (
        <div
        style={{zIndex:"99"}}
          className="origin-top-right fixed right-1 lg:right-0 mt-2 w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 "
          role="menu"
          aria-orientation="vertical"
        >
          <div
            className="p-1 lg:p-2 grid grid-cols-1 gap-1 text-white fixed z-40 right-1 bg-gray-800 rounded-md shadow-lg"
            role="none"
          >
            {languages.map((language) => (
              <button
                key={language.code}
                onClick={() => changeLanguage(language.code)}
                className="grid grid-cols-2  hover:bg-slate-900 p-2  lg:p-3 rounded-md"
                role="menuitem"
              >
                <img src={language.img} className="h-7" />
                <span className="truncate py-2 lg:py-1 text-xs lg:text-base">{language.lang}</span>
              </button>
            ))}
          </div>
        </div>
      )}
    </div>



  //   <div className="relative inline-block text-left">
  //   <button
  //     onClick={() => setIsOpen(!isOpen)}
  //     className="inline-flex items-center px-4 py-2 border border-gray-700 rounded-md shadow-sm bg-zinc-900 text-sm font-medium text-gray-200 hover:bg-zinc-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-zinc-700"
  //   >
  //     <img
  //       src={i18n.language === "th" ? TH_img : EN_img}
  //       className="h-7 w-7 rounded-full"
  //       alt="Language"
  //     />
  //     <svg
  //       className="ml-2 h-5 w-5 text-gray-400"
  //       xmlns="http://www.w3.org/2000/svg"
  //       viewBox="0 0 20 20"
  //       fill="currentColor"
  //       aria-hidden="true"
  //     >
  //       <path
  //         fillRule="evenodd"
  //         d="M5.23 7.21a.75.75 0 011.06.02L10 10.94l3.71-3.71a.75.75 0 111.06 1.06L10.53 12l4.24 4.24a.75.75 0 01-1.06 1.06L10 13.06l-3.71 3.71a.75.75 0 01-1.06-1.06L9.47 12 5.23 7.76a.75.75 0 01.02-1.06z"
  //         clipRule="evenodd"
  //       />
  //     </svg>
  //   </button>

  //   {isOpen && (
  //     <div
  //       className="absolute right-0 mt-2 w-44 bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 z-50"
  //     >
  //       <div className="py-1">
  //         {languages.map((language) => (
  //           <button
  //             key={language.code}
  //             onClick={() => changeLanguage(language.code)}
  //             className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
  //           >
  //             <img src={language.img} className="h-5 w-5 rounded-full mr-3" alt={language.lang} />
  //             <span>{language.lang}</span>
  //           </button>
  //         ))}
  //       </div>
  //     </div>
  //   )}
  // </div>
  );
}

export default LanguageSelector;
