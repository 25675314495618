import React, { useState, useEffect } from "react";
import config from "../../../../config/configapi";
import { Modal } from "antd";
import OpenNotification from "../../../../Toast/OpenNotification";
import ErrorMessage from "../../../../Toast/ErrorMessage";
import apiForm from "../../../../config/apiForm";

import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Switch,
} from "@mui/material";
import API_URL from "../../../../config/api";
import { useTranslation } from "react-i18next";
function AddGameModal({
  isModalGame,
  showModal,
  get_AllGame,
  allGame,
  campId,
  gameId,
}) {
  const { t } = useTranslation();
  const URL_HOST = `${config.API_SERVER}`;
  const [gamename, setGamename] = useState("");
  const [status, setStatus] = useState(true);
  const [cover, setCover] = useState("0");
  const [rtp, setRtp] = useState("");
  const [msgErr1, setMsgErr1] = useState("");

  const [imagegame, setImagegame] = useState({});
  const [imagePreviewgame, setImagePreviewgame] = useState(null);
  const [imagegameBackup, setImagegameBackup] = useState({});
  const [checkimagegame, setCheckimagegame] = useState(false);

  const [icongame, setIcongame] = useState({});
  const [iconPreviewgame, setIconPreviewgame] = useState(null);
  const [icongameBackup, setIcongameBackup] = useState({});
  const [checkicongame, setCheckicongame] = useState(false);

  useEffect(() => {
    clearForm();

    if (gameId != null) {
      const findone = allGame.find((obj) => {
        return obj.id === gameId;
      });
      setForm(findone);
    }
  }, [gameId]);

  const setForm = (findone) => {
    setGamename(findone.gamename);
    setStatus(findone.status === 1 ? true : false);
    setCover(findone.cover);
    setRtp(findone.percent_rtp);
    setImagePreviewgame(
      findone.imagegame !== null ? `${URL_HOST}${findone.imagegame}` : null
    );
    setImagegameBackup(findone.imagegame);
    setIconPreviewgame(
      findone.icongame !== null ? `${URL_HOST}${findone.icongame}` : null
    );
    setIcongameBackup(findone.icongame);
  };
  const clearForm = () => {
    setGamename("");
    setStatus(true);
    setCover("0");
    setRtp("");
    setImagePreviewgame(null);
    setImagegameBackup({});
    setIconPreviewgame(null);
    setIcongameBackup({});
  };

  const CreateGame = async () => {
    if (gamename === "") {
      setMsgErr1(`${t("fill_in_information_completely")}`);
      return;
    }
    setMsgErr1("");

    const formData = new FormData();
    formData.append("imagegame", imagegame);
    formData.append("icongame", icongame);
    formData.append("gamename", gamename);
    formData.append("status", status ? 1 : 0);
    formData.append("cover", cover);
    formData.append("rtp", rtp);

    formData.append("campId", campId);

    await apiForm
      .post(`api/game/newGame`, formData)
      .then((res) => {
        OpenNotification({ message: `${t("finish")}`, type: 1 });
        showModal();
        get_AllGame();
      })
      .catch((err) => {
        err.response.data.status === 400
          ? OpenNotification({
              message: `${t("there_already_this_name")}`,
              type: 3,
            })
          : OpenNotification({ message: `${t("an_error_occurred")}`, type: 4 });
      });
  };
  const UpdateGame = async () => {
    if (gamename === "") {
      setMsgErr1(`${t("fill_in_information_completely")}`);
      return;
    }
    setMsgErr1("");
    try {
      if (checkimagegame && imagegameBackup !== null) {
        let string = "";
        const array = imagegameBackup.split("\\");
        string = "./" + array.join("/");

        await API_URL.post(`api/game/deleteimageGame`, {
          imagegameBackup: string,
          id: gameId,
        });
      }
    } catch (e) {}
    try {
      if (checkicongame && icongameBackup !== null) {
        let string = "";
        const array = icongameBackup.split("\\");
        string = "./" + array.join("/");

        await API_URL.post(`api/game/deleteiconGame`, {
          icongameBackup: string,
          id: gameId,
        });
      }
    } catch (e) {}

    const formData = new FormData();
    formData.append("imagegame", imagegame);
    formData.append("icongame", icongame);
    formData.append("id", gameId);
    formData.append("gamename", gamename);
    formData.append("checkimagegame", checkimagegame);
    formData.append("checkicongame", checkicongame);
    formData.append("status", status ? 1 : 0);
    formData.append("cover", cover);
    formData.append("rtp", rtp);

    await apiForm
      .put(`api/game/updateGame`, formData)
      .then((res) => {
        OpenNotification({ message: `${t("finish")}`, type: 1 });
        showModal();
        get_AllGame();
        return res.data;
      })
      .catch((err) => {
        OpenNotification({ message: `${t("an_error_occurred")}`, type: 4 });
      });
  };
  const handleDelImageGame = (e) => {
    setImagegame({});
    setImagePreviewgame(null);
    setCheckimagegame(true);
  };
  const handleDelIconGame = (e) => {
    setIcongame({});
    setIconPreviewgame(null);
    setCheckicongame(true);
  };
  const handleUploadImageGame = (e) => {
    if (e.target.files[0].size > 20315594) {
      OpenNotification({
        message: `${t("image_size_must_not_exceed_20_mb")}`,
        type: 3,
      });
      return;
    }
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagegame(file);
      setImagePreviewgame(reader.result);
      setCheckimagegame(true);
    };
    reader.readAsDataURL(file);
  };
  const handleUploadIconGame = (e) => {
    if (e.target.files[0].size > 20315594) {
      OpenNotification({
        message: `${t("image_size_must_not_exceed_20_mb")}`,
        type: 3,
      });
      return;
    }
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setIcongame(file);
      setIconPreviewgame(reader.result);
      setCheckicongame(true);
    };
    reader.readAsDataURL(file);
  };
  const generateRandomNumber = (min,max) => {
    const randomNumber = (Math.random() * (max - min) + min).toFixed(2);
    setRtp(parseFloat(randomNumber));
  };
  return (
    <Modal
      style={{
        top: 10,
      }}
      title={gameId === null ? `${t("add_games")}` : `${t("edit_game")}`}
      open={isModalGame}
      onOk={showModal}
      onCancel={showModal}
      width={700}
      key={0}
      footer={[
        <div className=" text-center flex justify-center">
          {gameId === null ? (
            <button
              onClick={CreateGame}
              className="text-center text-white font-semibold  px-10 py-2 rounded-md bg-purple-500 hover:bg-purple-800 shadow-md"
            >
              {t("save")}
            </button>
          ) : (
            <button
              onClick={UpdateGame}
              className="text-center text-white font-semibold  px-10 py-2 rounded-md bg-orange-500 hover:bg-orange-800 shadow-md"
            >
              {t("edit")}
            </button>
          )}

          <div className="mx-5 md:mx-16 "></div>
          <button
            onClick={showModal}
            className="bg-transparent hover:bg-gray-100 text-black font-semibold  py-2 px-10 border border-purple-500 hover:border-transparent rounded"
          >
            {t("cancel")}
          </button>
        </div>,
      ]}
    >
      <div className="w-full py-4 ">
        <div className="w-full ">
          <div className="grid grid-cols-1 md:grid-cols-3 mt-8 gap-2">
            <div className="w-full rounded-lg shadow-md col-span-1 bg-gray-50">
              <div className="m-4">
                <div>
                  <label className="inline-block mb-2 text-gray-500">
                    {t("upload_icon_game_camp")}
                  </label>
                  <IconButton onClick={handleDelIconGame}>
                    <DeleteForeverIcon color="error" />
                  </IconButton>
                </div>

                <div className="flex items-center justify-center w-full">
                  {iconPreviewgame ? (
                    <img
                      className="rounded-sm h-36"
                      src={iconPreviewgame && iconPreviewgame}
                      alt="icon"
                    />
                  ) : (
                    <label className="flex flex-col w-full h-36 border-4 border-blue-200 border-dashed hover:bg-gray-100 hover:border-gray-300">
                      <div className="flex flex-col items-center justify-center mt-10">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-8 h-8 text-gray-400 group-hover:text-gray-600"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                          />
                        </svg>
                        <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                          Click
                        </p>
                      </div>
                      <input
                        type="file"
                        accept="image/png, image/jpeg,image/webp"
                        className="opacity-0"
                        name="imagedriving"
                        onChange={handleUploadIconGame}
                      />
                    </label>
                  )}
                </div>
              </div>
            </div>
            <div className="w-full rounded-lg shadow-md col-span-1 md:col-span-2 bg-gray-50">
              <div className="m-4">
                <div>
                  <label className="inline-block mb-2 text-gray-500">
                    {t("upload_picture_game")}
                  </label>
                  <IconButton onClick={handleDelImageGame}>
                    <DeleteForeverIcon color="error" />
                  </IconButton>
                </div>

                <div className="flex items-center justify-center w-full">
                  {imagePreviewgame ? (
                    <img
                      className="rounded-sm h-36"
                      src={imagePreviewgame && imagePreviewgame}
                      alt="nice2"
                    />
                  ) : (
                    <label className="flex flex-col w-full h-36 border-4 border-blue-200 border-dashed hover:bg-gray-100 hover:border-gray-300">
                      <div className="flex flex-col items-center justify-center mt-10">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-8 h-8 text-gray-400 group-hover:text-gray-600"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                          />
                        </svg>
                        <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                          {t("select_image_file")}
                        </p>
                      </div>
                      <input
                        type="file"
                        accept="image/png, image/jpeg,image/webp"
                        className="opacity-0"
                        name="imagedriving"
                        onChange={handleUploadImageGame}
                      />
                    </label>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full  flex justify-start mt-5">
          <div className="w-1/6 ">
            <p className="text-md mr-1 my-auto">{t("status")}</p>
            <Switch
              name="status"
              color="secondary"
              checked={status}
              onChange={(e) => {
                setStatus(!status);
              }}
            />
          </div>
          <div className="w-2"></div>
          <div className="w-5/6 ">
            <InputLabel id="demo-simple-select-label">
              {t("game_name")}
            </InputLabel>
            <div className="flex relative">
              <OutlinedInput
                value={gamename}
                onChange={(e) => setGamename(e.target.value)}
                className="block w-full  text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md   "
                aria-describedby="outlined-weight-helper-text"
                autoComplete="off"
                size="small"
                fullWidth
              />
            </div>
          </div>
        </div>
        <div className="w-full  flex flex-col md:flex-row justify-start mt-5">
          <div className="w-full md:w-1/6 ">
            <div className="flex w-full mx-auto my-5">
              <FormControl className="w-full whitespace-nowrap flex">
                <InputLabel className="my-auto">{t("cover")}</InputLabel>
                <Select
                  name="cover"
                  size="small"
                  className="flex"
                  inputProps={{
                    style: { display: "flex", whiteSpace: "nowrap" },
                  }}
                  label={t("cover")}
                  defaultValue={cover}
                  value={cover}
                  onChange={(e) => setCover(e.target.value)}
                  fullWidth
                >
                  <MenuItem value={0} className="flex align-middle">
                    {t("cover_none")}
                  </MenuItem>
                  <MenuItem value={1} className="flex align-middle">
                    new
                  </MenuItem>
                  <MenuItem value={2} className="flex align-middle">
                    hot
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="w-2"></div>
          <div className="w-full md:w-5/6 ">
            <InputLabel id="demo-simple-select-label">RTP</InputLabel>
            <div className="flex relative">
              <OutlinedInput
                value={rtp}
                onChange={(e) => setRtp(e.target.value)}
                className="block w-full  text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md   "
                aria-describedby="outlined-weight-helper-text"
                onKeyPress={(e) => !/[0-9.]/.test(e.key) && e.preventDefault()}
                autoComplete="off"
                size="small"
                fullWidth
                inputProps={{ maxLength: 6 }}
              />
              <div className="absolute right-2  bottom-0 top-0 flex items-center">
                <p className="my-auto text-xl font-medium ">%</p>
              </div>
            </div>
            <div className="grid grid-cols-3 md:grid-cols-5 gap-2 mt-2">
            <button
              onClick={()=>generateRandomNumber(50.00,60.00)}
              className="whitespace-nowrap mx-auto text-center text-white font-semibold  w-full py-2 rounded-md bg-green-600 hover:bg-green-800 shadow-md"
            >
              50 - 60 %
            </button>
            <button
              onClick={()=>generateRandomNumber(60.00,70.00)}
              className="whitespace-nowrap mx-auto text-center text-white font-semibold  w-full py-2 rounded-md bg-green-600 hover:bg-green-800 shadow-md"
            >
              60 - 70 %
            </button>
            <button
              onClick={()=>generateRandomNumber(70.00,80.00)}
              className="whitespace-nowrap mx-auto text-center text-white font-semibold  w-full py-2 rounded-md bg-green-600 hover:bg-green-800 shadow-md"
            >
              70 - 80 %
            </button>
            <button
              onClick={()=>generateRandomNumber(80.00,90.00)}
              className="whitespace-nowrap mx-auto text-center text-white font-semibold  w-full py-2 rounded-md bg-green-600 hover:bg-green-800 shadow-md"
            >
              80 - 90 %
            </button>
            <button
              onClick={()=>generateRandomNumber(90.00,100.00)}
              className="whitespace-nowrap mx-auto text-center text-white font-semibold  w-full py-2 rounded-md bg-green-600 hover:bg-green-800 shadow-md"
            >
              90 - 100 %
            </button>

            </div>
          </div>
        </div>
      </div>
      {msgErr1 ? <ErrorMessage message={msgErr1} /> : <></>}
    </Modal>
  );
}

export default AddGameModal;
