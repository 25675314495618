import Swal from "sweetalert2";

const windowWidth = window.innerWidth;
let widthPer = windowWidth < 768 ? "65%" : "25%";

const Toast2 = Swal.mixin({
    toast: true,
    position: 'bottom-start',
    showConfirmButton: false,
    timer: 1000,
    width:widthPer,
    customClass:"text-xs lg:text-base ",
    timerProgressBar: true,
    didOpen: (toast) => {
    //   toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  })
export default Toast2;
