import i18n from 'i18next'
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector'
if (!localStorage.getItem('i18nextLngAdmin')) {
    localStorage.setItem('i18nextLngAdmin', 'th');
}
const i18nAdmin = i18n.createInstance();
i18nAdmin.use(LanguageDetector).use(initReactI18next).init({
    // debug: true,
    fallbackLng: "th",
    // lng: 'en',
    detection: {
        lookupLocalStorage: 'i18nextLngAdmin',
    },
    resources: {
        en: {
            translation: {
                name: "Name",
                login: "LOGIN",
                usernameorpasswordincorrect: "Username or password is incorrect",
                logingedin: "Logged in",
                password: "Password",
                menu_home: "Home",
                menu_user: "User",
                menu_manage_credit: "Manage Credit",
                menu_game_camp: "Game camp",
                menu_media: "Media",
                menu_setting: "Setting",
                menu_manage_admin: "Manage Admin",
                menu_account: "Account",
                menu_history: "History",
                menu_bonustime: "Bonus Time",
                logout: "Logout",
                all_user: "All user",
                new_user_today: "New user today",
                new_users_this_month: "New user this month",
                today_credit_addition: "Today's credit addition",
                credit_increase_this_month: "Credit increase this month",
                today_credit_reduction: "Today's credit reduction",
                credit_reduction_this_month: "Credit reduction this month",
                no_number: "No.",
                phone: "Phone",
                credit: "Credit",
                create_date: "Create date",
                lastest_user: "Latest user",
                nodata: "No data",
                finish: "Finish",
                this_username_does_not_exist: "This username does not exist",
                an_error_occurred: "An error occurred.",
                confirm_delete_this_user: "Confirm to delete this user?",
                confirm_deletion: "Confirm deletion",
                cancel: "Cancel",
                deleted_successfully: "Deleted successfully",
                management: "Management",
                total_number_user: "Total number of users",
                add_user: "Add user",
                search_credit_phone_number: "Search credit/phone number",
                please_fill_phone: "Please fill in your phone number correctly and completely",
                please_enter_password_8_character: "Please enter a correct password and contain at least 8 characters",
                user_added_successfully: "User added successfully",
                phone_or_username_already_use: "This number or username is already in use",
                an_error_or_number_username_already_use: "An error has occurred or this number/username is already in use",
                user_been_success_edited: "User has been successfully edited",
                edit_user: "Edit user",
                save: "Save",
                edit: "Edit",
                back: "Back",
                edit_information: "Edit information",
                start: "Start",
                end: "End",
                credit_add_reduce_type: "Credit add/reduce type",
                original_amount: "Original amount",
                balance: "Balance",
                time: "Time",
                total_credit_added: "Total credit added",
                total_credit_reduction: "Total credit reduction",
                search: "Search",
                reduce: "Reduce",
                increase: "Increase",
                search_completed: "Search completed",
                credit_history: "Credit history",
                note: "Note",
                add_reduce_credit: "Add/Reduce Credit",
                please_enter_number_correctly: "Please enter the number correctly",
                please_enter_username_phone: "Enter Username / phone number",
                this_username_does_exist: "This username doesn't exist.",
                confirm: "Confirm",
                credit_types: "Credit Types",
                add_game_camps: "Add game camps",
                edit_game_camp: "Edit game camp",
                image: "Image",
                game_camp_name: "Game camp name",
                game_name: "Game name",
                status: "Status",
                total_games: "Total games",
                view_all: "View all",
                confirm_delete_this_camp: "Confirm to delete this camp?",
                fill_in_information_completely: "Fill in the information completely",
                there_already_this_name: "There is already this name",
                image_size_must_not_exceed_20_mb: "Image size must not exceed 20 MB",
                upload_picture_game_camp: "Upload picture of game camp",
                upload_icon_game_camp: "Upload icon (size 1:1)",
                upload_picture_game: "Upload picture of game",
                select_image_file: "Select image file",
                all_games: "All games",
                add_games: "Add games",
                edit_game: "Edit games",
                sort_by: "Sort by",
                sort_rtp_high_to_low: "% RTP High to Low",
                sort_rtp_low_to_high: "% RTP Low to High",
                cover: "Cover",
                cover_none: "none",
                please_upload_picture: "Please upload a picture",
                upload_image: "Upload image",
                language: "Language",
                recommended_image: "Recommended image",
                banner_image: "Banner image",
                recommended: "Recommended",
                banner: "Banner",
                all_photo: "All photos",
                confirm_delete_this_image: "Confirm to delete this image?",
                link: "Link",
                website_information: "Website information",
                upload_website_logo: "Upload website logo (recommended size 1:1)",
                website_name: "Website name (not more than 18 characters)",
                website: "Website",
                total_number_admin: "Total admin",
                add_admin: "Add admin",
                edit_admin: "Edit admin",
                confirm_delete_this_admin: "Confirm to delete this admin?",
                please_enter: "Please Enter",
                there_already_this_admin: "There is already this admin.",
                passwordisincorrect: "Password is incorrect",
                newpasswordnotmatch: "New passwords do not match",
                change_password: "Change password",
                old_password: "Old password",
                new_password: "New password",
                confirm_new_password: "Confirm new password",
                old_password_incorrect: "Old password is incorrect.",
                new_password_do_not_match: "New passwords do not match",
                account_information_success_edit_loginAgain: "Account information has been successfully edited!!!! Please log in again.",
                back: "Back",
                amount_already_zero: "Credit amount is already 0",
                random: "Random",
                all: "All",
                icon: "Icon",
                preset: "Preset",
                add: "Add",
                select_game: "Select games",
                select: "Select",
                selected:"Selected",
                add_least_one_game:"Add at least 1 game"
            }
        }, th: {
            translation: {
                name: "ชื่อ",
                login: "เข้าสู่ระบบ",
                usernameorpasswordincorrect: "Username หรือรหัสผ่านไม่ถูกต้อง",
                logingedin: "เข้าสู่ระบบแล้ว",
                password: "รหัสผ่าน",
                menu_home: "หน้าแรก",
                menu_user: "สมาชิก",
                menu_manage_credit: "จัดการเครดิต",
                menu_game_camp: "ค่ายเกม",
                menu_media: "คลังรูป",
                menu_setting: "ตั้งค่า",
                menu_manage_admin: "จัดการแอดมิน",
                menu_account: "บัญชี",
                menu_history: "ประวัติ",
                menu_bonustime: "ตั้งเวลาโบนัส",
                logout: "ออกจากระบบ",
                all_user: "User ทั้งหมด",
                new_user_today: "User ใหม่วันนี้",
                new_users_this_month: "User รวมเดือนนี้",
                today_credit_addition: "ยอดเพิ่มเครดิตวันนี้",
                credit_increase_this_month: "ยอดเพิ่มเครดิตเดือนนี้",
                today_credit_reduction: "ยอดลดวันนี้",
                credit_reduction_this_month: "ยอดลดรวมเดือนนี้",
                no_number: "ลำดับ",
                phone: "เบอร์โทร",
                credit: "เครดิต",
                create_date: "สร้างเมื่อ",
                lastest_user: "User ล่าสุด",
                nodata: "ไม่มีข้อมูล",
                finish: "เสร็จสิ้น",
                this_username_does_not_exist: "ไม่มี Username นี้",
                an_error_occurred: "เกิดข้อผิดพลาด",
                confirm_delete_this_user: "ยืนยันที่จะลบ user นี้?",
                confirm_deletion: "ยืนยันการลบ",
                cancel: "ยกเลิก",
                deleted_successfully: "ลบเรียบร้อย",
                management: "การจัดการ",
                total_number_user: "จำนวน User",
                add_user: "เพิ่ม User",
                search_credit_phone_number: "ค้นหาเครดิต/เบอร์โทร",
                please_fill_phone: "กรุณากรอกเบอร์โทรให้ถูกต้องและครบถ้วน",
                please_enter_password_8_character: "กรุณากรอกรหัสผ่านให้ถูกต้องและมี 8 ตัวอักษรขึ้นไป",
                user_added_successfully: "เพิ่ม User เรียบร้อย",
                phone_or_username_already_use: "เบอร์นี้ถูกใช้งานแล้ว",
                an_error_or_number_username_already_use: "เกิดข้อผิดพลาดหรือ เบอร์/username นี้มีคนใช้แล้ว",
                user_been_success_edited: "แก้ไข user เรียบร้อย",
                edit_user: "แก้ไข user",
                save: "บันทึก",
                edit: "แก้ไข",
                back: "ย้อนกลับ",
                edit_information: "แก้ไขข้อมูล",
                start: "เริ่มต้น",
                end: "สิ้นสุด",
                credit_add_reduce_type: "ประเภทเพิ่ม / ลด",
                note: "หมายเหตุ",
                credit_history: "ประวัติเครดิต",
                original_amount: "ยอดเดิม",
                balance: "คงเหลือ",
                time: "เวลา",
                total_credit_added: "รวมยอดเพิ่ม",
                total_credit_reduction: "รวมยอดลด",
                search: "ค้นหา",
                reduce: "ลด",
                increase: "เพิ่ม",
                search_completed: "ค้นหาเสร็จสิ้น",
                add_reduce_credit: "เพิ่ม / ลดเครดิต",
                please_enter_number_correctly: "กรุณากรอกตัวเลขให้ถูกต้อง",
                please_enter_username_phone: "กรุณากรอก username / เบอร์โทร",
                this_username_does_exist: "ไม่มี Username นี้",
                confirm: "ยืนยัน",
                credit_types: "ประเภทเครดิต",
                add_game_camps: "เพิ่มค่ายเกม",
                image: "รูป",
                game_camp_name: "ชื่อค่ายเกม",
                game_name: "ชื่อเกม",
                status: "สถานะ",
                total_games: "เกมทั้งหมด",
                view_all: "ดูทั้งหมด",
                confirm_delete_this_camp: "ยืนยันที่จะลบค่ายนี้?",
                confirm_delete_this_game: "ยืนยันที่จะลบเกมนี้?",
                fill_in_information_completely: "กรอกข้อมูลให้ครบถ้วน",
                there_already_this_name: "มีชื่อนี้แล้ว",
                image_size_must_not_exceed_20_mb: "ขนาดรูปภาพต้องไม่เกิน 20 MB",
                upload_picture_game_camp: "อัพโหลดรูปค่ายเกม",
                upload_icon_game_camp: "อัพโหลด Icon (size 1:1)",
                upload_picture_game: "อัพโหลดรูปเกม",
                select_image_file: "เลือกไฟล์รูปภาพ",
                all_games: "เกมทั้งหมด",
                add_games: "เพิ่มเกม",
                sort_by: "เรียงตาม",
                sort_rtp_high_to_low: "% RTP มาก ไป น้อย",
                sort_rtp_low_to_high: "% RTP น้อย ไป มาก",
                cover: "ปกรูป",
                cover_none: "ไม่ใส่",
                please_upload_picture: "กรุณาอัพโหลดรูปภาพ",
                upload_image: "อัพโหลดรูปภาพ",
                language: "ภาษา",
                recommended_image: "รูปแนะนำ",
                banner_image: "รูป Banner",
                recommended: "แนะนำ",
                banner: "Banner",
                all_photo: "รูปทั้งหมด",
                confirm_delete_this_image: "ยืนยันที่จะลบรูปนี้?",
                link: "ลิงค์",
                website_information: "ข้อมูลเว็บไซต์",
                upload_website_logo: "อัพโหลด Logo เว็บไซต์ (แนะนำขนาด 1:1)",
                website_name: "ชื่อเว็บไซต์ (ไม่เกิน 18 ตัว)",
                website: "เว็บไซต์",
                total_number_admin: "จำนวน Admin",
                add_admin: "เพิ่ม Admin",
                edit_admin: "แก้ไข Admin",
                confirm_delete_this_admin: "ยืนยันที่จะลบ admin นี้?",
                please_enter: "กรุณากรอก",
                there_already_this_admin: "มี admin นี้อยู่แล้ว",
                passwordisincorrect: "รหัสผ่านไม่ถูกต้อง",
                newpasswordnotmatch: "รหัสผ่านใหม่ไม่ตรงกัน",
                change_password: "เปลี่ยนรหัสผ่าน",
                old_password: "รหัสผ่านเดิม",
                new_password: "รหัสผ่านใหม่",
                confirm_new_password: "ยืนยันรหัสผ่านใหม่",
                old_password_incorrect: "กรอกรหัสผ่านเดิมไม่ถูกต้อง",
                new_password_do_not_match: "กรอกรหัสผ่านใหม่ไม่ตรงกัน",
                account_information_success_edit_loginAgain: "แก้ไขข้อมูลบัญชีเรียบร้อย!!!! โปรดเข้าสู่ระบบใหม่",
                back: "ย้อนกลับ",
                amount_already_zero: "จำนวนเครดิตคงเหลือ 0 อยู่แล้ว",
                random: "สุ่ม",
                all: "ทั้งหมด",
                icon: "ไอคอน",
                preset: "ตั้งล่วงหน้า",
                add: "เพิ่ม",
                select_game: "เลือกเกม",
                select: "เลือก",
                selected: "เลือกไว้",
                add_least_one_game:"เพิ่มอย่างน้อย 1 เกม"
            }
        }
    }
})
export default i18nAdmin;