import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { IconButton } from "@mui/material";
import config from "../../../../config/configapi";
import Loading from "../../../../Toast/LoadingScreen";
import API_URL from "../../../../config/api";
import { LazyLoadImage } from "react-lazy-load-image-component";
import imageEmpty from "../../../../image/emptyimg.png";
import ProgressBar from "@ramonak/react-progress-bar";
import hot_icon from "../../../../icon/iconhot710_583.gif";
import new_icon from "../../../../icon/new-animated-gif-icon.gif";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import OpenNotification from "../../../../Toast/OpenNotification";
import dayjs from "dayjs";
import Countdown_UI from "./Countdown_UI";

import Toast2 from "../../../../Toast/Toast2";
import api from "../../../../config/api";
import apiForm from "../../../../config/apiForm";
import axios from "axios";
import Lottie from "lottie-react";

import new_icon_anime from "../../../../Json/new_icon_anime2.json";
import hot_icon_anime from "../../../../Json/hot_icon_anime.json";
import { TOKEN_KEY } from "../../../../config/token_key";

function Game() {
  const { t } = useTranslation();
  let { id } = useParams();
  const navigate = useNavigate();
  const URL_HOST = `${config.API_SERVER}`;

  const [peopleId] = useState(localStorage.getItem(`${TOKEN_KEY}Id`));
  const userdata = JSON.parse(
    localStorage.getItem(`${TOKEN_KEY}Userdata`)
  );
  const [loading, setLoading] = useState(true);
  const [loadingCamp, setLoadingCamp] = useState(true);
  const [loadingAccess, setLoadingAccess] = useState(true);

  const [allGame, setAllGame] = useState([]);
  const [oneCamp, setOneCamp] = useState({});

  const [endTime, setEndTime] = useState(null);

  const toHome = () => {
    navigate("/");
  };
  useEffect(() => {
    get_AllGame();
    get_OneCamp();
  }, [id]);

  const get_AllGame = async () => {
    setLoading(true);
    await API_URL.get(`api/game/allGameUser/${id}`)
      .then((res) => {
        const getallgame = res.data;
        if (getallgame.length !== 0) {
          setAllGame(getallgame);
        }
      })
      .catch((err) => {
        OpenNotification({
          message: `${t("data_transmission_error")}`,
          type: 4,
        });
      });
    setLoading(false);
  };

  const get_OneCamp = async () => {
    setLoadingCamp(true);
    await API_URL.get(`api/camp/oneCamp/${id}`)
      .then(async (res) => {
        const getallcamp = res.data;
        if (getallcamp.length !== 0) {
          setOneCamp(getallcamp);
          // *************************
          // setLoadingAccess(true);

          // await API_URL.post(`api/timerestriction/checkTime`, {
          //   campId: getallcamp.id,
          //   peopleId: peopleId,
          //   campname: getallcamp.campname,
          // })
          //   .then(async (res) => {
          //     if (res.data.access) {
          //       setEndTime(res.data.endTime);
          //     } else {
          //       window.location.href = `/`;
          //     }
          //   })
          //   .catch(async (err) => {
          //     err.response.data.status === 401
          //       ? await Toast2.fire({
          //           icon: "error",
          //           title: `${t("not_enough_credit")}`,
          //         })
          //       : err.response.data.status === 402
          //       ? await Toast2.fire({
          //           icon: "warning",
          //           title: `${oneCamp.campname} ${t("timeup")}`,
          //         })
          //       : OpenNotification({
          //           message: `${t("data_transmission_error")}`,
          //           type: 4,
          //         });
          //     window.location.href = `/`;
          //   });
          // setLoadingAccess(false);
          // *************************
        }
      })
      .catch((err) => {
        OpenNotification({
          message: `${t("data_transmission_error")}`,
          type: 4,
        });
      });
    setLoadingCamp(false);
  };

  const checkAccess = async () => {
    setLoadingAccess(true);
    await API_URL.post(`api/timerestriction/checkTime`, {
      campId: oneCamp.id,
      peopleId: peopleId,
      campname: oneCamp.campname,
    })
      .then(async (res) => {
        if (res.data.access) {
          setEndTime(res.data.endTime);
        } else {
          window.location.href = `/`;
        }
      })
      .catch(async (err) => {
        err.response.data.status === 401
          ? await Toast2.fire({
              icon: "error",
              title: `${t("not_enough_credit")}`,
            })
          : err.response.data.status === 402
          ? await Toast2.fire({
              icon: "warning",
              title: `${oneCamp.campname} ${t("timeup")}`,
            })
          : OpenNotification({
              message: `${t("data_transmission_error")}`,
              type: 4,
            });
        window.location.href = `/`;
      });
    setLoadingAccess(false);
  };

  const getProgressBarColor = (percent) => {
    if (percent < 20) return "red";
    if (percent < 40) return "#ff8200";
    if (percent < 60) return "#dfb000";
    if (percent < 80) return "#26b326";
    return "#0075c9";
  };
  const gettextColor = (percent) => {
    if (percent <= 55) return "black";

    return "white";
  };
  const gettextShadowColor = (percent) => {
    if (percent <= 55) return "";

    return "1px 1px 1px #000000";
  };
  const getCoverColor = (cover) => {
    if (cover === 0) return "#ffffff36";
    if (cover === 1) return "#ffffff36";
    return "#fb0e0e2e";
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="grid grid-cols-3">
            <div className="flex justify-start align-middle">
              {" "}
              <button
                onClick={() => toHome()}
                className="text-white flex align-middle  bg-purple-800 hover:bg-purple-700 rounded-md px-2 py-1 mb-1 text-xs lg:text-sm"
              >
                <ArrowBackIosIcon fontSize="medium" className="text-white" />
               <p className="my-auto">{t("back")}</p> 
              </button>
            </div>
            <div className="text-center my-auto">
              <p className="text-center text-white">
                {loadingCamp ? "" : oneCamp.campname}
              </p>
            </div>
            {/* <div className="text-right my-auto">
              {loadingAccess ? (
                <></>
              ) : (
                <Countdown_UI
                  checkAccess={checkAccess}
                  date={dayjs(endTime).toDate()}
                />
              )}
            </div> */}
          </div>
          <div className="h-0.5 bg-slate-400"></div>
          {allGame.length === 0 ? (
            <h2 className="text-center mt-10 text-white">{t("comingsoon")}</h2>
          ) : (
            <div className="grid grid-cols-3 gap-2 lg:grid-cols-6 lg:gap-4 py-1">
              {allGame.map((game) => (
                <div
                  className="p-1 lg:p-2 game_card rounded-md relative"
                  key={game.id}
                >
                  <div
                    className="absolute top-1 left-1   text-white text-xs px-0.5 rounded-md"
                    // style={{ backgroundColor: getCoverColor(game.cover) }}
                  >
                    {/* <Lottie
                        className="w-12 lg:w-16 -my-3 -ml-1 lg:-my-2 lg:-ml-0"
                        animationData={new_icon_anime}
                        loop={true}
                      /> */}
                    {game.cover === 0 ? (
                      <></>
                    ) : game.cover === 1 ? (
                      <Lottie
                        className="w-12 md:w-16 -ml-2 -mt-2 lg:-mt-0 lg:-ml-0"
                        style={{ filter: "drop-shadow(0px 1px 2px black)" }}
                        animationData={new_icon_anime}
                        loop={true}
                      />
                    ) : (
                      <Lottie
                        className="w-5 md:w-7 "
                        style={{ filter: "drop-shadow(0px 1px 2px black)" }}
                        animationData={hot_icon_anime}
                        loop={true}
                      />
                    )}
                  </div>
                  <LazyLoadImage
                    className="w-full  rounded-md pointer-events-none "
                    style={{ borderRadius: "8px" }}
                    src={
                      `${URL_HOST}${game.imagegame}` !== null
                        ? `${URL_HOST}${game.imagegame}`
                        : game.imagegame !== null
                        ? `${URL_HOST}${game.imagegame}`
                        : imageEmpty
                    }
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = imageEmpty;
                    }}
                    draggable="false"
                    alt={game.gamename}
                  />
                  <div className="my-1">
                    <Tooltip
                      title={game.gamename}
                      className="w-full text-center"
                      color={"purple"}
                    >
                      <span className="text-ellipsis overflow-hidden line-clamp-1 text-white text-center text-xs lg:text-base">
                        {game.gamename}
                      </span>
                    </Tooltip>

                    {/* <div className="progress-container1 w-5/6 mx-auto">
                    <ProgressBar
                      completed={game.percent_rtp}
                      labelSize="13px"
                      labelAlignment="center"
                      height="13px"
                      maxCompleted={100}
                      animateOnRender={true}
                      bgColor={getProgressBarColor(game.percent_rtp)}
                      labelColor="transparent"
        
                    />
                    <p className="custom-label text-xs lg:text-xs text-white w-full text-center"style={{
                        color: gettextColor(game.percent_rtp),
                        textShadow: gettextShadowColor(game.percent_rtp),
                      }}>
                      RTP {game.percent_rtp}%
                    </p>
                  </div> */}
                  </div>
                  <div className="my-0.5 lg:my-1 w-full lg:w-5/6 mx-auto">
                    <div className="progress-bar">
                      <div
                        className="progress-fill"
                        style={{
                          width: `${game.percent_rtp}%`,
                          backgroundColor: getProgressBarColor(
                            game.percent_rtp
                          ),
                        }}
                      ></div>
                      <span
                        className="progress-label text-xs"
                        style={{
                          color: gettextColor(game.percent_rtp),
                          textShadow: gettextShadowColor(game.percent_rtp),
                        }}
                      >{`RTP ${game.percent_rtp}%`}</span>
                    </div>
                  </div>
                  <div className="text-center mt-1">
                    <form
                      action="https://188bet.zzttyy.com/cust-login"
                      method="post"
                    >
                      <input
                        type="hidden"
                        name="url"
                        value={window.location.href}
                      />
                      <input
                        type="hidden"
                        name="username"
                        value={userdata.user.phone}
                      />
                      <input
                        type="hidden"
                        name="password"
                        value={userdata.user.password}
                      />
                      <button
                        type="submit"
                        className="text-xs lg:text-base font-medium text-shadow-1 w-5/6 lg:w-2/3  py-1 bg-gradient-to-r from-blue-500 to-purple-700 text-white  rounded-full transition-transform transform-gpu hover:-translate-y-1 hover:shadow-lg"
                      >
                        {t("enter_to_play")}
                      </button>
                    </form>
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Game;
