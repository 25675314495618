import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import bg from "../../../../image/bg2.jpg";
import { Button } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import API_URL from "../../../../config/api";
import config from "../../../../config/configapi";
import imageEmpty from "../../../../image/emptyimg.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

import DraggableImageGrid from "./DraggableImageGrid";
import LoadingScreen from "../../../../Toast/LoadingScreen";
import OpenNotification from "../../../../Toast/OpenNotification";
import { useTranslation } from "react-i18next";

function Game() {
  const {t} = useTranslation();
  let { campId } = useParams();
  const navigate = useNavigate();
  const URL_HOST = `${config.API_SERVER}`;
  const [allCamp, setAllCamp] = useState({});
  const [allGame, setAllGame] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalGame, setIsModalGame] = useState(false);
  const [gameId, setGameId] = useState(null);

  useEffect(() => {
    get_AllCamp();
    get_AllGame();
  }, []);
  const get_AllCamp = async () => {
    await API_URL.get(`api/camp/oneCamp/${campId}`)
      .then((res) => {
        const getallcamp = res.data;
        if (getallcamp.length !== 0) {
          setAllCamp(getallcamp);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const get_AllGame = async () => {
    setLoading(true);
    await API_URL.get(`api/game/allGame/${campId}`)
      .then((res) => {
        const getallgame = res.data;
        if (getallgame.length !== 0) {
          setAllGame(getallgame);
        }
      })
      .catch((err) => {
        OpenNotification({ message: `${t("an_error_occurred")}`, type: 4 });
      });
    setLoading(false);
  };
  const showModal = () => {
    setGameId(null);
    setIsModalGame(!isModalGame);
  };

  const handleOrderChange = async (newOrder) => {
    try {
      const updatedItems = newOrder.map((item, index) => ({
        id: item.id,
        order: index + 1,
      }));   
      await API_URL.put(`api/game/updateOrder`, updatedItems).then((res) => {
        get_AllGame();
      });
    } catch (error) {
      OpenNotification({ message: `${t("an_error_occurred")}`, type: 4 });
    }
  };

  return (
    <div className="bg-gray-100"
      style={{
        // backgroundImage: `url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
        minHeight: "100vh",
        height: "100%",
      }}
    >
      <nav className="flex items-center justify-between h-14 bg-gray-500 rounded-md px-6 py-3 md:py-5 lg:py-2 w-full z-30 drop-shadow-xl max-h-20">
        <div className="w-2/4 ">
          <button
            onClick={() => navigate(-1)}
            className="bg-orange-500 hover:bg-orange-700 text-shadow-1  text-white  font-bold py-2 px-4 rounded-md inline-flex items-center shadow-md"
          >
            <svg
              className="drop-shadow-1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              width="24"
              height="24"
              strokeWidth="1.5"
            >
              <path d="M13.883 5.007l.058 -.005h.118l.058 .005l.06 .009l.052 .01l.108 .032l.067 .027l.132 .07l.09 .065l.081 .073l.083 .094l.054 .077l.054 .096l.017 .036l.027 .067l.032 .108l.01 .053l.01 .06l.004 .057l.002 .059v12c0 .852 -.986 1.297 -1.623 .783l-.084 -.076l-6 -6a1 1 0 0 1 -.083 -1.32l.083 -.094l6 -6l.094 -.083l.077 -.054l.096 -.054l.036 -.017l.067 -.027l.108 -.032l.053 -.01l.06 -.01z"></path>
            </svg>
            <span className="ml-2"> {t("back")}</span>
          </button>
        </div>
      </nav>
      {loading ? (
        <LoadingScreen />
      ) : (
        <div>
          <div className="grid grid-cols-2 lg:grid-cols-6 gap-2">
            <div className="p-3">
              <LazyLoadImage
                className="h-full lg:h-32 object-contain m-0 rounded-md"
                src={
                  `${URL_HOST}${allCamp.imagecamp}` !== null
                    ? `${URL_HOST}${allCamp.imagecamp}`
                    : null && allCamp.imagecamp !== null
                    ? `${URL_HOST}${allCamp.imagecamp}`
                    : imageEmpty
                }
                onError={({ currentTarget }) => {
                  currentTarget.onerror =
                    `${URL_HOST}${allCamp.imagecamp}` !== null
                      ? `${URL_HOST}${allCamp.imagecamp}`
                      : null; // prevents looping
                  currentTarget.src = imageEmpty;
                }}
              />
            </div>

            <div className="flex gap-2 flex-col my-auto col-span-2">
              <p className="text-lg "> {t("name")} : {allCamp.campname}</p>
              <p className="text-lg">{t("all_games")} : {allGame.length}</p>
            </div>

            <div className="flex justify-start col-span-3">
              <button
                onClick={showModal}
                className=" text-center py-2 md:py-2 px-10 rounded-md shadow-lg bg-red-500 hover:bg-red-700 text-white text-sm md:text-md h-10 my-auto"
              >
                {t("add_games")}
              </button>
            </div>
          </div>

          <div className=" mx-auto px-2">
            <DraggableImageGrid
              allGame={allGame}
              onOrderChange={handleOrderChange}
              isModalGame={isModalGame}
              showModal={showModal}
              get_AllGame={get_AllGame}
              gameId={gameId}
              campId={campId}
              setGameId={setGameId}
              setIsModalGame={setIsModalGame}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Game;
