import React, { useState, useEffect, useRef, createContext } from "react";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  HistoryOutlined,
  HomeOutlined,
  UserOutlined,
  LogoutOutlined,
  TeamOutlined,
  RocketOutlined,
  SettingFilled,
  SolutionOutlined,
  PictureOutlined,
  FieldTimeOutlined
} from "@ant-design/icons";

import { Link, Route, Routes, useLocation } from "react-router-dom";
import { Layout, Menu, Button, theme } from "antd";

import Home from "./Home/Home";
import User_all from "./User/User_all";
import Credit from "./Credit/MainCredit";

import Contact from "./Contact/Contact";
import Admin_list from "./Admin_list/Admin";
import { logout, getCurrentUser } from "../.././services/auth.service";
import Userdetail from "./User/Userdetail/Userdetail";

import { WebSocketProvider } from "./WebSocketContext";
import authHeader from "../../services/auth-header";
import Account from "./Account/Account";
import Logoweb from "../../Function/Logoweb";
import Nameweb from "../../Function/Nameweb";
import Camp from "./Camp/Camp";
import Game from "./Camp/Game/Game";
import Banner from "./Banner/Banner";
import LanguageSelectorAdmin from "../language-selector-admin";
import { useTranslation } from "react-i18next";
import History from "./History/History";
import { Bonustime, Preset } from "./Bonustime/Bonustime";

import { UpdateBonustime } from "./Bonustime/UpdateBonustime";
import AddBonustime from "./Bonustime/AddBonustime";
import TestInput from "./Bonustime/TestInput";

const { Header, Sider, Content, Footer } = Layout;
export const DashboardContext = createContext();
function Dashboard() {
  let urlpath = useLocation();
  const {t} = useTranslation();
  const [collapsed, setCollapsed] = useState(true);
  const [oldpath, setOldpath] = useState(urlpath.pathname);

  const [token] = useState(getCurrentUser());

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const handleLogout = () => {
    window.location.href = "/admin";
    logout();
  };

  let items_mod = [
    {
      key: "/admin",
      name: `${t("menu_home")}`,
      icon: <HomeOutlined />,
      label: (
        <Link to="/admin">
          <span className="nav-text">{t("menu_home")}</span>
        </Link>
      ),
    },
    {
      key: "/admin/user",
      name: `${t("menu_user")}`,
      icon: <TeamOutlined />,

      label: (
        <Link to="/admin/user">
          <span className="nav-text">{t("menu_user")}</span>
        </Link>
      ),
    },
    // {
    //   key: "/admin/credit",
    //   name: `${t("menu_manage_credit")}`,
    //   icon: <CreditCardOutlined />,
    //   label: (
    //     <Link to="/admin/credit">
    //       <span className="nav-text">{t("menu_manage_credit")}</span>
    //     </Link>
    //   ),
    // },

    {
      key: "/admin/camp",
      name: `${t("menu_game_camp")}`,
      icon: <RocketOutlined />,
      label: (
        <Link to="/admin/camp">
          <span className="nav-text">{t("menu_game_camp")}</span>
        </Link>
      ),
    },
    {
      key: "/admin/bonustime",
      name: `${t("menu_bonustime")}`,
      icon: <FieldTimeOutlined />,
      label: (
        <Link to="/admin/bonustime">
          <span className="nav-text">{t("menu_bonustime")}</span>
        </Link>
      ),
    },
    {
      key: "/admin/media",
      name: `${t("menu_media")}`,
      icon: <PictureOutlined />,
      label: (
        <Link to="/admin/media">
          <span className="nav-text">{t("menu_media")}</span>
        </Link>
      ),
    },
    // {
    //   key: "/admin/transaction",
    //   name: "รายการถอน",
    //   icon: <AppstoreOutlined />,
    //   label: (
    //     <Link to="/admin/transaction">
    //       <span className="nav-text">รายการถอน</span>
    //     </Link>
    //   ),
    // },
    // {
    //   key: "/admin/trading",
    //   name: "รายการเทรด",
    //   icon: <OrderedListOutlined />,
    //   label: (
    //     <Link to="/admin/trading">
    //       <span className="nav-text">รายการเทรด</span>
    //     </Link>
    //   ),
    // },
    // {
    //   key: "/admin/verify",
    //   name: "ยืนยันตัวตน",
    //   icon: <SafetyOutlined />,
    //   label: (
    //     <Link to="/admin/verify">
    //       <span className="nav-text">ยืนยันตัวตน</span>
    //     </Link>
    //   ),
    // },

    // {
    //   key: "/admin/addstock",
    //   icon: <AreaChartOutlined />,
    //   label: (
    //     <Link to="/admin/addstock">
    //       <span className="nav-text">หุ้น</span>
    //     </Link>
    //   ),
    // },
    // {
    //   key: "/admin/payrate",
    //   name: "อัตราจ่าย",
    //   icon: <CalculatorOutlined />,
    //   label: (
    //     <Link to="/admin/payrate">
    //       <span className="nav-text">อัตราจ่าย</span>
    //     </Link>
    //   ),
    // },
    {
      key: "/admin/history",
      name: `${t("menu_history")}`,
      icon: <HistoryOutlined />,
      label: (
        <Link to="/admin/history">
          <span className="nav-text">{t("menu_history")}</span>
        </Link>
      ),
    },
    {
      key: "/admin/setting",
      name: `${t("menu_setting")}`,
      icon: <SettingFilled />,
      label: (
        <Link to="/admin/setting">
          <span className="nav-text">{t("menu_setting")}</span>
        </Link>
      ),
    },
  ];
  if (token != null && token.roles[0] === "ROLE_MOD") {
    items_mod.push(
      {
        key: "/admin/management",
        name: `${t("menu_manage_admin")}`,
        icon: <SolutionOutlined />,
        label: (
          <Link to="/admin/management">
            <span className="nav-text">{t("menu_manage_admin")}</span>
          </Link>
        ),
      },
      {
        key: "/admin/account",
        name: `${t("menu_account")}`,
        icon: <UserOutlined />,
        label: (
          <Link to="/admin/account">
            <span className="nav-text">{t("menu_account")}</span>
          </Link>
        ),
      },
      {
        key: "8",
        name: `${t("logout")}`,
        icon: <LogoutOutlined />,
        label: (
          <Link onClick={handleLogout}>
            <span className="nav-text">{t("logout")}</span>
          </Link>
        ),
      }
    );
  } else {
    items_mod.push(
      {
        key: "/admin/account",
        name: `${t("menu_account")}`,
        icon: <UserOutlined />,
        label: (
          <Link to="/admin/account">
            <span className="nav-text">{t("menu_account")}</span>
          </Link>
        ),
      },
      {
        key: "8",
        name: `${t("logout")}`,
        icon: <LogoutOutlined />,
        label: (
          <Link onClick={handleLogout}>
            <span className="nav-text">{t("logout")}</span>
          </Link>
        ),
      }
    );
  }

  // const [currentItem,setCurrentItem] = useState(items_mod.find(item => item.key === urlpath.pathname))
  let currentItem = items_mod.find((item) => item.key === urlpath.pathname);

  useEffect(() => {
    authHeader();
    window.scrollTo(0, 0);
    currentItem = items_mod.find((item) => item.key === urlpath.pathname);
  }, [urlpath.pathname]);

  const mobileCLickMenu = () => {
    setCollapsed(!collapsed);
  };
  // useEffect(() => {
  //   if (oldpath !== urlpath.pathname) {
  //     setCollapsed(!collapsed);
  //     setOldpath(urlpath.pathname);
  //   }
  // }, [urlpath.pathname]);
  return (
    <WebSocketProvider>
      <DashboardContext.Provider>
        <Layout>
          <Sider
            trigger={null}
            collapsible
            collapsed={!collapsed}
            width={200}
            className="hidden lg:block"
          >
            <div className="mx-auto">
              <div className="mx-auto text-center h-7 lg:h-12 my-2 object-contain flex justify-center">
                <Logoweb />
              </div>
              <div className="text-white mb-4 text-center truncate">
                <Nameweb />
              </div>
            </div>

            <Menu
              className="h-screen"
              theme="dark"
              mode="inline"
              // onClick={()=>setCollapsed(!collapsed)}
              defaultSelectedKeys={[urlpath.pathname]}
              items={items_mod}
            ></Menu>
          </Sider>
          <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            width={200}
            className="block lg:hidden"
          >
            <div>
              <div className="mx-auto  text-center  h-12 my-2 object-contain flex justify-center">
                <Logoweb />
              </div>
              <div className="text-white mb-4 text-center truncate">
                <Nameweb />
              </div>
            </div>

            <Menu
              className="h-screen"
              theme="dark"
              mode="inline"
              subMenuCloseDelay={2}
              onClick={() => setCollapsed(!collapsed)}
              defaultSelectedKeys={[urlpath.pathname]}
              items={items_mod}
            ></Menu>
          </Sider>
          <Layout>
            <Header
              style={{
                padding: 0,
                background: colorBgContainer,
              }}
              className="flex "
            >
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => setCollapsed(!collapsed)}
                className="hidden lg:block"
                style={{
                  fontSize: "16px",
                  width: 64,
                  height: 64,
                }}
              />
              <Button
                type="text"
                icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                onClick={() => mobileCLickMenu()}
                className="block lg:hidden"
                style={{
                  fontSize: "16px",
                  width: 64,
                  height: 64,
                }}
              />
              <p className="whitespace-nowrap">{currentItem ? currentItem.name : ""}</p>
              <div className="grid justify-end w-full pr-3">
        <LanguageSelectorAdmin />
      </div>
            </Header>
            <Content
              className="min-h-screen h-full w-full  min-w-full my-6 mx-1 p-2"
              style={{
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/user" element={<User_all />} />
                <Route path="/user/:peopleId" element={<Userdetail />}></Route>
                
                {/* <Route path="/credit" element={<Credit />} /> */}
                <Route path="/history" element={<History />} />
                <Route path="/camp" element={<Camp />} />

                <Route path="/camp/:campId" element={<Game />}></Route>
                <Route path="/bonustime" element={<Bonustime />} />
                <Route path="/bonustime/add" element={<AddBonustime />} />
                <Route path="/bonustime/:presetId" element={<AddBonustime />} />
                {/* <Route path="/bonustime/add" element={<TestInput />} /> */}
                {/* <Route path="/bonustime/update/:presetId" element={<UpdateBonustime />} /> */}
                <Route path="/media" element={<Banner />}></Route>

                <Route path="/setting" element={<Contact />} />
                <Route path="/management" element={<Admin_list />} />
                <Route path="/account" element={<Account />} />
              </Routes>
            </Content>
            <Footer
              style={{
                textAlign: "center",
              }}
              className="text-xs"
            >
              ©{new Date().getFullYear()} . ALL RIGHTS RESERVED.
            </Footer>
          </Layout>
        </Layout>
      </DashboardContext.Provider>
    </WebSocketProvider>
  );
}

export default Dashboard;
