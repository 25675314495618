import React, { useContext, useMemo, useState, useEffect } from "react";
import { HolderOutlined } from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CreateIcon from "@mui/icons-material/Create";
import SearchIcon from "@mui/icons-material/Search";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { IconButton } from "@mui/material";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {  Table, Spin } from "antd";

import API_URL from "../../../config/api";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import OpenNotification from "../../../Toast/OpenNotification";
import config from "../../../config/configapi";
import imageEmpty from "../../../image/emptyimg.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";




export const Bonustime = () => {
  const { t } = useTranslation();
  const URL_HOST = `${config.API_SERVER}`;
  const [allPreset, setAllPreset] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    get_AllPreset();
  }, []);

  const get_AllPreset = async () => {
    setLoading(true);
    await API_URL.get(`api/preset/allPreset`)
      .then((res) => {
        const getallPreset = res.data;
        if (getallPreset.length !== 0) {
          setAllPreset(getallPreset);
        }
      })
      .catch((err) => {
        OpenNotification({ message: `${t("an_error_occurred")}`, type: 4 });
      });
    setLoading(false);
  };

  const columns = [

    {
      title: `${t("no_number")}`,
      dataIndex: "order",
      key: "order",
      width: "5%",
      render: (order) => <p className="text-xs  my-auto truncate"> {order}</p>,
    },
    {
      title: `${t("name")}`,
      dataIndex: "name",
      width: "10%",
      render: (name) => (
        <p className="text-sm font-semibold  my-auto truncate max-w-52">
          {name}
        </p>
      ),
    },
    {
      title: `${t("all")}`,
      dataIndex: "total_games",
      width: "6%",
      render: (total_games) => (
        <p className="text-xs  my-auto truncate "> {total_games}</p>
      ),
    },
    {
      title: `${t("all_games")}`,
      dataIndex: "preset_games",
      render: (preset_games) => (
        <div className="grid grid-cols-4 md:grid-cols-6 gap-2">
          {preset_games.map((game) => (
            <div className="grid grid-cols-1 justify-center mx-auto text-center">
              <LazyLoadImage
                className="h-14 object-contain m-0 rounded-md"
                src={
                  `${URL_HOST}${game.game.imagegame}` !== null
                    ? `${URL_HOST}${game.game.imagegame}`
                    : null && game.game.imagegame !== null
                    ? `${URL_HOST}${game.game.imagegame}`
                    : imageEmpty
                }
                onError={({ currentTarget }) => {
                  currentTarget.onerror =
                    `${URL_HOST}${game.game.imagegame}` !== null
                      ? `${URL_HOST}${game.game.imagegame}`
                      : null; // prevents looping
                  currentTarget.src = imageEmpty;
                }}
              />
              <div className="flex justify-start truncate text-xs md:text-sm">
                <p>{game.startTime}</p>
                <p className="mx-2">-</p>
                <p>{game.endTime}</p>
              </div>
            </div>
          ))}
        </div>
      ),
    },

    {
      title: `${t("status")}`,

      render: (allPreset) => (
        <>
          {allPreset.status === 1 ? (
            <button className="bg-green-50  text-green-800 border-2 border-green-500 font-bold py-2 px-4 rounded-md inline-flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                width="24"
                height="24"
              >
                <path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z"></path>
              </svg>{" "}
              <span className="ml-2">{t("selected")}</span>
            </button>
          ) : (
            <button onClick={()=>updateSelectPreset(allPreset.id)} className="bg-green-500 hover:bg-green-600  text-white text-shadow-1  border-2  font-bold py-2 px-4 rounded-lg inline-flex items-center">
              <svg
              className="drop-shadow-1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                width="24"
                height="24"
                strokeWidth="1.5"
              >
                <path d="M3 12l3 0"></path>
                <path d="M12 3l0 3"></path>
                <path d="M7.8 7.8l-2.2 -2.2"></path>
                <path d="M16.2 7.8l2.2 -2.2"></path>
                <path d="M7.8 16.2l-2.2 2.2"></path>
                <path d="M12 12l9 3l-4 2l-2 4l-3 -9"></path>
              </svg>
              <span className="ml-2">{t("select")}</span>
            </button>
          )}
        </>
      ),
    },

    {
      title: `${t("management")}`,
      dataIndex: "id",
      key:"id",
      width: "15%",
      render: (id, allPreset) => (
        <div className="flex">
          <Link
           to={`/admin/bonustime/${id}`}
            className="my-auto flex align-middle bg-slate-100 hover:bg-slate-200 rounded-md px-2 py-0.5"
          >
            <CreateIcon fontSize="medium" color="warning" />
            <p>{t("edit")}</p>
          </Link>
          <IconButton
          onClick={() => showDeleteConfirm(id, allPreset)}
          >
            <DeleteForeverIcon fontSize="small" color="error" />
          </IconButton>
        </div>
      ),
    },
  ];

  const showDeleteConfirm = (id, preset) => {
    Swal.fire({
      title: `${t("confirm_delete_this_camp")}`,
      text: preset.name,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `${t("confirm_deletion")}`,
      cancelButtonText: `${t("cancel")}`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await API_URL.delete(`api/preset/deletePreset/${id}`)
          .then(async () => {
            get_AllPreset();

            OpenNotification({
              message: `${t("deleted_successfully")}`,
              type: 1,
            });
          })
          .catch((err) => {
            OpenNotification({ message: `${t("an_error_occurred")}`, type: 4 });
          });
      }
    });
  };

  const updateSelectPreset =async (id)=>{
    await API_URL.put(`api/preset/selectPreset/${id}`)
    .then(async () => {
      get_AllPreset();
      OpenNotification({
        message: `${t("finish")}`,
        type: 1,
      });
    })
    .catch((err) => {
      OpenNotification({ message: `${t("an_error_occurred")}`, type: 4 });
    });
  }



  return (
    <div>
      <div className="grid grid-cols-2">
        <div className="flex justify-start">
          <Link
            to={`/admin/bonustime/add`}
            className=" justify-center text-center py-2 md:py-2 px-10 rounded-md shadow-lg bg-red-500 hover:bg-red-700 text-white text-sm md:text-md"
          >
            {t("add")} Preset
          </Link>
        </div>
        <div></div>
      </div>

          <Table
            rowKey={(record) => record.id}
            scroll={{
              x: 800,
            }}

            columns={columns}
            dataSource={allPreset}
            loading={{
              indicator: (
                <div>
                  <Spin size="large" />
                </div>
              ),
              spinning: loading,
            }}
          />

    </div>
  );
};
