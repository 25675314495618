import React, { useState, useEffect, useRef, createContext } from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";

import { ConfigProvider, theme } from "antd";

import API_URL from "../../config/api";
import { IconButton } from "@mui/material";
import config from "../../config/configapi";

import { WebSocketUserProvider } from "./WebSocketUser";
import LanguageSelector from "../language-selector";
import LogoutIcon from "@mui/icons-material/Logout";
import ChatwootComponent from "./ChatwootWidget";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Logoweb from "../../Function/Logoweb";
import Nameweb from "../../Function/Nameweb";
import authUser from "../../services/auth-user";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import coin_icon from "../../icon/coin_icon.png";
import NumberFormat2float from "../../Function/NumberFormat2float";
import { useTranslation } from "react-i18next";
import Loading from "../../Toast/LoadingScreen";
import Camp from "./Camp/Camp";
import Game from "./Camp/Game/Game";
import bg1 from "../../image/wallpaper_login_main2.jpg";
import ChatWidget from "./ChatWidget";
import ChatWidget2 from "./ChatWidget2";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import Bonustime from "./Bonustime/Bonustime";

import icon_camp from "../../icon/slot_icon.png";
import icon_bonustime from "../../icon/bonus_icon.png";
import icon_new from "../../icon/new_text.png";
import { TOKEN_KEY } from "../../config/token_key";

export const DashboardContext = createContext();
function Dashboard() {
  const { t } = useTranslation();

  const { defaultAlgorithm, darkAlgorithm } = theme;
  const [loading, setLoading] = useState(false);
  const [userdata, setUserdata] = useState({});
  const URL_HOST = `${config.API_SERVER}`;

  const [facebookURL, setFacebookURL] = useState("");
  const [lineURL, setLineURL] = useState("");
  const [websiteURL, setWebsiteURL] = useState("");
  const [gmailURL, setGmailURL] = useState("");

  const [idbank, setIdbank] = useState("");
  const [codebank, setCodebank] = useState("");
  const [namebank, setNamebank] = useState("");
  const [imgbank, setImgbank] = useState(null);
  const [bankdetail, setBankdetail] = useState("");

  const [isLoggedOut, setIsLoggedOut] = useState(false);

  const [userId] = useState(
    JSON.parse(localStorage.getItem(`${TOKEN_KEY}Id`))
  );
  const [user] = useState( JSON.parse(localStorage.getItem(`${TOKEN_KEY}Userdata`)));

  let pathname = useLocation();

  useEffect(() => {
    authUser();
    get_user();
    getWebUrl();
    window.scrollTo(0, 0);
  }, [pathname]);

  const getWebUrl = async () => {
    await API_URL.get(`api/weburl/getallweburl`)
      .then((res) => {
        const allweburl = res.data;
        if (allweburl.length !== 0) {
          const resFacebook = allweburl.find(({ name }) => name === "facebook");
          setFacebookURL(resFacebook.nameurl);
          const resLine = allweburl.find(({ name }) => name === "line");
          setLineURL(resLine.nameurl);
          const resWebsite = allweburl.find(({ name }) => name === "website");
          setWebsiteURL(resWebsite.nameurl);
          const resGmail = allweburl.find(({ name }) => name === "gmail");
          setGmailURL(resGmail.nameurl);
          const residbank = allweburl.find(({ name }) => name === "idbank");
          setIdbank(residbank.nameurl);
          const rescodebank = allweburl.find(({ name }) => name === "codebank");
          setCodebank(rescodebank.nameurl);
          const resnamebank = allweburl.find(({ name }) => name === "namebank");
          setNamebank(resnamebank.nameurl);
          const resimgbank = allweburl.find(({ name }) => name === "imgbank");
          setImgbank(URL_HOST + resimgbank.nameurl);
          const resbankdetail = allweburl.find(
            ({ name }) => name === "bankdetail"
          );
          setBankdetail(resbankdetail.nameurl);
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const get_user = async () => {
    setLoading(true);
    await API_URL.get(`api/people/oneUserdata/${userId}`)
      .then((res) => {
        const getoneuser = res.data.user;

        setUserdata(getoneuser);
      })
      .catch((err) => {
        logout_people();
      });
    setLoading(false);
  };

  const logout_people =async () => {
    console.log(user.token);
    
      await API_URL.post(`api/people/logout`,{xaccesstoken: user.token})
      .then((res) => {
        localStorage.removeItem(`${TOKEN_KEY}Id`);
  
        localStorage.removeItem(`${TOKEN_KEY}Userdata`);
    
        setIsLoggedOut(true);
    
        window.location.href = `/`;
      })
      .catch((err) => {
  
      });

  };

  return (
    <WebSocketUserProvider>
      <ConfigProvider
        theme={{
          algorithm: darkAlgorithm,
        }}
      >
        <DashboardContext.Provider
          value={{
            userId,
            get_user,
            userdata,
          }}
        >
          <div
            className=" min-h-screen pb-20"
            style={{
              backgroundImage: `url(${bg1})`,
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "fixed",
              backgroundPosition: "top right",
              backgroundSize: "cover",
              minHeight: "100vh",
              paddingBottom: "200px",
              height: "100%",
            }}
          >
            <div className="w-full p-1  fixed z-50 top-0  shadow-lg bg_nav_user text-white">
              <div className="grid grid-cols-3 justify-between items-center mb-1  mx-auto">
                <div className="flex  justify-start">
                  {loading ? (
                    <></>
                  ) : (
                    <div className="text-left  w-full lg:w-1/2">
                      {/* <IconButton onClick={() => logout()}>
                        <LogoutIcon fontSize="medium" className="text-white" />
                      </IconButton> */}
                      <button
                        onClick={() => logout_people()}
                        className="text-left  text-shadow-1  text-white  font-bold py-2 px-6 rounded-lg inline-flex items-center shadow-md bg-white bg-opacity-25"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          width="24"
                          height="24"
                          strokeWidth="1.5"
                        >
                          <path d="M10 8v-2a2 2 0 0 1 2 -2h7a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-7a2 2 0 0 1 -2 -2v-2"></path>
                          <path d="M15 12h-12l3 -3"></path>
                          <path d="M6 15l-3 -3"></path>
                        </svg>
                        {/* <span className="ml-2 font-semibold"> {t("logout")}</span> */}
                      </button>
                    </div>
                  )}
                </div>
                <div className="block lg:flex justify-center mx-auto">
                  <div className="my-auto  text-center  h-16 lg:h-16 mx-2 object-contain ">
                    <Logoweb />
                  </div>
                </div>
                <div className="w-full  flex justify-end">
                  <LanguageSelector />
                </div>
              </div>
              <div className="grid grid-cols-2 lg:grid-cols-3">
                <div className="flex align-middle my-auto">
                  <AccountCircleIcon />
                  <p className="text-base">
                    {loading ? "" : `${userdata.username}`}
                  </p>
                </div>
                <div className="hidden lg:block"></div>
                <div className="flex justify-end align-middle">
                  {/* <div className="px-2 py-2 bg-purple-800 rounded-md flex align-middle my-auto">
                    <img src={coin_icon} className="h-6 mr-1" />
                    <p className="text-base">
                      {t("credit")} :{" "}
                      {loading ? "" : `${NumberFormat2float(userdata.credit)}`}
                    </p>
                  </div> */}
                  <div className="text-center mt-1 w-full lg:w-1/2">
                    <form
                      action="https://188bet.zzttyy.com/cust-login"
                      method="post"
                    >
                      <input
                        type="hidden"
                        name="url"
                        value={window.location.href}
                      />
                      <input
                        type="hidden"
                        name="username"
                        value={userdata.phone}
                      />
                      <input
                        type="hidden"
                        name="password"
                        value={userdata.password}
                      />
                      <button
                        type="submit"
                        className="updown1 flex align-middle justify-center text-xs lg:text-base font-medium text-shadow-1 w-full  py-1 bg-gradient-to-r from-blue-500 to-purple-700 hover:from-blue-600 hover:to-purple-800 text-white  rounded-md transition-transform transform-gpu  hover:shadow-lg"
                      >
                        <SportsEsportsIcon className="mr-1 shadow-md drop-shadow-1" />{" "}
                        <p className="my-auto text-shadow-1">
                          {t("enter_to_play")}
                        </p>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-32 px-1 md:px-2">
              {loading ? (
                <Loading />
              ) : (
                <Routes>
                  <Route path="/" element={<Camp userdata={userdata} />} />
                  <Route path="/bonustime" element={<Bonustime />} />
                  <Route
                    path="/games/:id"
                    element={<Game userdata={userdata} />}
                  ></Route>
                </Routes>
              )}
            </div>
          </div>
          {/* {loading ? (
            <></>
          ) : (
            <ChatWidget2 userdata={userdata} isLoggedOut={isLoggedOut} />
          )} */}

          <div
            style={{ zIndex: "9999" }}
            className=" fixed bottom-0 left-0 right-0 rounded-t-3xl bg-gradient-to-t to-purple-600 from-purple-900 text-white grid grid-cols-3 justify-around items-center py-0.5 md:py-2 shadow-md border-t-2 border-sky-900 "
          >
            <div className=" px-2 ">
              <Link
                to={"/"}
                className={
                  "flex flex-col lg:flex-row  items-center justify-center py-0.5 " +
                  (pathname.pathname === "/" ? "" : "")
                }
              >
                <LazyLoadImage
                  src={icon_camp}
                  className={
                    "size-10 mr-1 " +
                    (pathname.pathname === "/" ? "shake_icon" : "")
                  }
                />
                <span className="text-xs md:text-sm">{t("game")}</span>
              </Link>
            </div>
            <div className="">
              <form action="https://188bet.zzttyy.com/cust-login" method="post">
                <input type="hidden" name="url" value={window.location.href} />
                <input type="hidden" name="username" value={userdata.phone} />
                <input
                  type="hidden"
                  name="password"
                  value={userdata.password}
                />
                <button
                  type="submit"
                  className="absolute -top-5 md:-top-8 left-1/2 transform -translate-x-1/2 bg-gradient-to-b from-blue-400 to-purple-700 hover:from-blue-600 hover:to-purple-800 text-white rounded-3xl px-10 md:px-16 lg:px-28  py-2 md:py-5  shadow-lg flex flex-col md:flex-row items-center border-2 border-purple-500"
                >
                  <SportsEsportsIcon className="mr-1 drop-shadow-1" />
                  <span className="text-sm text-shadow-2">
                    {t("enter_to_play")}
                  </span>
                </button>
              </form>
            </div>
            <div className=" px-2 relative">
              <LazyLoadImage
                className="w-full h-12 md:h-16 lg:h-14 object-contain m-0 rounded-md absolute -top-10 md:-top-14 lg:-top-12 updown2"
                src={icon_new}
                style={{
                  userSelect: "none",
                  pointerEvents: "none",
                  WebkitTouchCallout: "none",
                }}
                onContextMenu={(e) => e.preventDefault()} // ป้องกันการคลิกขวา
                draggable="false"
              />
              <Link
                to={"/bonustime"}
                className={
                  "flex flex-col lg:flex-row  items-center justify-center  rounded-xl py-0.5 " +
                  (pathname.pathname === "/bonustime"
                    ? // ? "bg-gradient-to-t from-stone-700 to-purple-700 bg-opacity-50"
                      ""
                    : "")
                }
              >
                <LazyLoadImage
                  src={icon_bonustime}
                  className={
                    "size-10 mr-1 " +
                    (pathname.pathname === "/bonustime" ? "shake_icon" : "")
                  }
                />
                <span className="text-xs md:text-sm">{t("bonustime")}</span>
              </Link>
            </div>
          </div>

          {/* <ChatwootComponent /> */}

          {/* <div className="bg-zinc-900"></div>
          <footer
            className="px-9 py-5 text-center bg-gray-800"
            style={{
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "scroll",
              backgroundSize: "cover",
            }}
          >
            <div>
              <div className="flex justify-center">
                <div className="  text-center  h-10 lg:h-16 mx-2 object-contain ">
                  <Logoweb />
                </div>
                <div className="text-white my-auto">
                  <Nameweb />
                </div>
              </div>
            </div>

            <div>
              <p className="text-lg text-white font-semibold bg-slate-900 px-3 py-1 rounded-md w-3/6 lg:w-1/6 text-center mx-auto">
                contact
              </p>
            </div>

            <div className="grid grid-cols-4 w-full lg:w-2/5 mx-auto mt-2 ">
              <div>
                <a
                  className="text-center flex-col text-base font-semibold"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={facebookURL}
                >
                  <LazyLoadImage
                    loading="lazy"
                    src={social1}
                    className="w-9 md:w-10  mx-5 my-2 inline"
                  />
                  <p className="text-white text-xs lg:text-base font-semibold">
                    FACEBOOK
                  </p>
                </a>
              </div>
              <div>
                <a
                  className="text-center flex-col text-base"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={lineURL}
                >
                  <LazyLoadImage
                    loading="lazy"
                    src={social2}
                    className="w-9 md:w-10  mx-5 my-2 inline"
                  />
                  <p className="text-white text-xs lg:text-base font-semibold">
                    LINE
                  </p>
                </a>
              </div>
              <div>
                <a
                  className="text-center flex-col text-base"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={gmailURL}
                >
                  <LazyLoadImage
                    loading="lazy"
                    src={social4}
                    className="w-9 md:w-10  mx-5 my-2 inline"
                  />
                  <p className="text-white text-xs lg:text-base font-semibold">
                    GMAIL
                  </p>
                </a>
              </div>
              <div>
                <a
                  className="text-center flex-col text-base"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={websiteURL}
                >
                  <LazyLoadImage
                    loading="lazy"
                    src={social3}
                    className="w-9 md:w-10  mx-5 my-2 inline"
                  />
                  <p className="text-white text-xs lg:text-base font-semibold">
                    WEBSITE
                  </p>
                </a>
              </div>
            </div>
            <div className="bg-gray-700 h-0.5 my-5 w-5/6 mx-auto"></div>

            <div className="mt-3 text-white text-xs lg:text-base pb-16 lg:pb-1">
              <p>© 2018. ALL RIGHTS RESERVED.</p>
            </div>
          </footer> */}
        </DashboardContext.Provider>
      </ConfigProvider>
    </WebSocketUserProvider>
  );
}

export default Dashboard;
