import React, { useState, useEffect, useCallback } from "react";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";

import imageEmpty from "../../../image/emptyimg.png";
import config from "../../../config/configapi";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { IconButton } from "@mui/material";
import Swal from "sweetalert2";
import API_URL from "../../../config/api";
import OpenNotification from "../../../Toast/OpenNotification";
import TH_icon from "../../../icon/th_icon.png";
import EN_icon from "../../../icon/en_icon.png";
import MM_icon from "../../../icon/mm_icon.png";
import { useTranslation } from "react-i18next";

const DraggableBanner = ({ allHeader, onOrderChange, get_AllBannerHeader }) => {
  const {t} = useTranslation();
  const URL_HOST = `${config.API_SERVER}`;
  const [items, setItems] = useState(allHeader);
  const [boxesPerRow, setBoxesPerRow] = useState(4);
  const [heightPerRow, setHeightPerRow] = useState(180);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setBoxesPerRow(windowWidth < 768 ? 2 : 4);
    setHeightPerRow(windowWidth < 768 ? 120 : 180);
  }, [windowWidth]);

  useEffect(() => {
    setItems(allHeader);
  }, [allHeader]);

  const onChange = useCallback(
    (sourceId, sourceIndex, targetIndex) => {
      const nextState = swap(items, sourceIndex, targetIndex);
      const updatedItems = nextState.map((item, index) => ({
        ...item,
        order: index + 1,
      }));
      setItems(updatedItems);
      onOrderChange(updatedItems);
    },
    [items, onOrderChange]
  );

  const showDeleteConfirm = async (id, header) => {
    await Swal.fire({
      title: `${t("confirm_delete_this_image")}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: `${t("confirm_deletion")}`,
      cancelButtonText: `${t("cancel")}`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        await API_URL.delete(`api/banner/deleteBanner/${id}`)
          .then(async () => {
            try {
              get_AllBannerHeader();
            } catch (error) {
              console.log("Failed:", error);
            }
            try {
              if (header.imagebanner !== null) {
                let string = "";
                const array = header.imagebanner.split("\\");
                string = "./" + array.join("/");

                await API_URL.post(`api/banner/deleteimageBanner`, {
                  id: null,
                  imagebannerBackup: string,
                });
              }
            } catch (e) {}
            OpenNotification({ message: `${t("deleted_successfully")}`, type: 1 });
          })
          .catch((err) => {
            OpenNotification({ message: `${t("an_error_occurred")}`, type: 4 });
          });
      }
    });
  };

  return (
    <div>
      <GridContextProvider onChange={onChange}>
        <GridDropZone
          id="items"
          boxesPerRow={boxesPerRow}
          rowHeight={heightPerRow}
          style={{
            height: `${Math.ceil(items.length / boxesPerRow) * heightPerRow}px`,
          }}
        >
          {items.map((header) => (
            <GridItem key={header.id} className="py-1">
              <div className="bg-gray-500 p-1 m-0.5  lg:m-2 rounded-lg shadow cursor-move relative h-full flex flex-col">
                <div className="absolute top-0.5 left-0.5 lg:top-1 lg:left-1  text-black bg-white  text-xs p-1 rounded-sm flex align-middle">
                  <img
                  className="h-5 mr-1"
                    src={
                      header.language === "TH"
                        ? TH_icon
                        : header.language === "EN"
                        ? EN_icon
                        : MM_icon
                    }
                  />
                  <p className="text-sm text-black">{header.type===1?"Banner":`${t("recommended")}`}</p>
                </div>
                <div className="absolute top-1 right-1 lg:top-3 lg:right-3  text-white text-xs px-1 rounded-sm">
                  <IconButton
                    sx={{ backgroundColor: "#1c1c1cd4" }}
                    onClick={() => showDeleteConfirm(header.id, header)}
                  >
                    <DeleteForeverIcon fontSize="small" color="error" />
                  </IconButton>
                </div>
                <div
                  className="flex-grow flex items-center justify-center overflow-hidden"
                  style={{ height: "100px" }}
                >
                  <LazyLoadImage
                    className="w-full h-full object-contain pointer-events-none"
                    style={{ borderRadius: "12px" }}
                    src={
                      `${URL_HOST}${header.imagebanner}` !== null
                        ? `${URL_HOST}${header.imagebanner}`
                        : header.imagebanner !== null
                        ? `${URL_HOST}${header.imagebanner}`
                        : imageEmpty
                    }
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = imageEmpty;
                    }}
                    draggable="false"
                  />
                </div>
              </div>
            </GridItem>
          ))}
        </GridDropZone>
      </GridContextProvider>
    </div>
  );
};

export default DraggableBanner;
